
  const __registry__ = require('../../../spiferack/client/index.js')
  // @ts-nocheck
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _require = require('react-helmet'),
    Helmet = _require.Helmet;

var PropTypes = require('prop-types');
var React = require('react');

var GenericInput = require('../../components/inputs/generic');
var GenericForm = require('../../components/forms/generic');
var LoginLockIcon = require('../../components/icons/login-lock');
var LiminalLoginLayout = require('../../components/layouts/liminal-login');
var UserAvatarHeaderComponent = require('../../components/auth/user-avatar-header-component');

var _require2 = require('./liminal-login-footer'),
    AccountRecoverySupportFooter = _require2.AccountRecoverySupportFooter;

var EmailOTPLogin = function (_React$PureComponent) {
  _inherits(EmailOTPLogin, _React$PureComponent);

  function EmailOTPLogin() {
    _classCallCheck(this, EmailOTPLogin);

    return _possibleConstructorReturn(this, (EmailOTPLogin.__proto__ || Object.getPrototypeOf(EmailOTPLogin)).apply(this, arguments));
  }

  _createClass(EmailOTPLogin, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          action = _props.action,
          _props$formData = _props.formData,
          formData = _props$formData === undefined ? {} : _props$formData,
          email = _props.email,
          isMFASupportEnabledForUser = _props.isMFASupportEnabledForUser,
          isNewCliLoginFlowEscalation = _props.isNewCliLoginFlowEscalation,
          isInitialOTP = _props.isInitialOTP,
          user = _props.user,
          isAutomatedRecoveryEnabled = _props.isAutomatedRecoveryEnabled;


      var onSubmit = null;
      // noop to get default form submit
      if (action.match(/^\/login/)) onSubmit = function onSubmit() {};

      var Header = isNewCliLoginFlowEscalation && user ? React.createElement(UserAvatarHeaderComponent, _extends({ tokenUser: user }, this.props)) : 'Login Verification';
      var sectionHeader = React.createElement(
        'h2',
        { className: 'tc' },
        'Enter One-time Password.'
      );
      var Footer = isMFASupportEnabledForUser && !isInitialOTP ? React.createElement(AccountRecoverySupportFooter, { accountRecoveryKeyword: 'email', isAutomatedRecoveryEnabled: isAutomatedRecoveryEnabled }) : null;

      return React.createElement(
        LiminalLoginLayout,
        { Footer: Footer, Header: Header },
        React.createElement(
          'div',
          null,
          React.createElement(
            Helmet,
            null,
            React.createElement(
              'title',
              null,
              'npm | Enter a One-time Password'
            )
          ),
          React.createElement(
            GenericForm,
            {
              action: action,
              formId: 'login',
              formData: formData,
              onSubmit: onSubmit,
              buttonText: 'Login',
              className: 'center' },
            React.createElement(
              'div',
              { className: 'tc' },
              React.createElement(LoginLockIcon, null)
            ),
            React.createElement(
              'div',
              null,
              sectionHeader
            ),
            React.createElement(
              'p',
              null,
              'For your security, we\'ve sent a one-time password to your email address at ',
              email,
              '.'
            ),
            React.createElement(
              'p',
              null,
              'You will be required to enter a One-Time Password sent via email on every login. If you enable ',
              React.createElement(
                'a',
                { href: 'https://docs.npmjs.com/configuring-two-factor-authentication' },
                'two-factor authentication'
              ),
              ', you can use your device to authenticate instead of checking your email.'
            ),
            React.createElement(GenericInput, { inputMode: 'numeric', autoComplete: 'off', autoFocus: true, name: 'otp', label: 'One-Time Password', formData: formData })
          )
        )
      );
    }
  }]);

  return EmailOTPLogin;
}(React.PureComponent);

EmailOTPLogin.layout = 'none';

EmailOTPLogin.propTypes = {
  action: PropTypes.string.isRequired
};

module.exports = EmailOTPLogin;
  let __hot__
  
  __registry__.register('auth/email-otp', module.exports, __hot__)
  
'use strict'

const React = require('react')
const PropTypes = require('prop-types')
const styles = require('./selectable-table.css')
const connect = require('../connect')
const formIdConsumer = require('../inputs/form-id-consumer')

class SelectableTable extends React.PureComponent {
  constructor (props) {
    super(props)

    this.onCheckboxCheck = this.onCheckboxCheck.bind(this)
    this.onAllCheck = this.onAllCheck.bind(this)
  }

  render () {
    const { headings, rows, name, readOnly } = this.props
    const value = this.props.formData.value || []

    return (
      <table className={styles.table}>
        <TableHead
          headings={headings}
          onChange={() => this.onAllCheck()}
          readOnly={readOnly}
          selected={value.length === rows.length} />
        <tbody>
          {rows.map((row, idx) => (
            <TableRow
              index={idx}
              row={row}
              selected={value.indexOf(row.key) > -1}
              name={name}
              key={row.key}
              readOnly={readOnly}
              onChange={() => this.onCheckboxCheck(row.key)} />
          ))}
        </tbody>
      </table>
    )
  }

  componentDidMount () {
    const { name, formId } = this.props
    this.props.dispatch({
      type: 'FORM_VALIDITY_CHECK',
      name,
      formId,
      errorMessage: null
    })
  }

  onCheckboxCheck (key) {
    const { name, formId } = this.props
    const oldValue = this.props.formData.value || []
    const newValue = toggleKey(oldValue, key)

    this.props.dispatch({
      type: 'FORM_CHANGE',
      name,
      formId,
      value: newValue
    })
    this.props.onChange(newValue, key)
  }

  onAllCheck () {
    const { name, formId } = this.props
    const oldValue = this.props.formData.value || []
    let newValue

    if (oldValue.length === this.props.rows.length) {
      newValue = []
    } else {
      newValue = this.props.rows.map(row => row.key)
    }

    this.props.dispatch({
      type: 'FORM_CHANGE',
      name,
      formId,
      value: newValue
    })
    return this.props.onChange(newValue)
  }
}

function TableHead (props) {
  return (
    <thead>
      <tr>
        <th className={styles.tableHead}>
          {!props.readOnly && <input
            type='checkbox'
            checked={props.selected}
            onChange={props.onChange}
            aria-label='Select all' />}
        </th>
        {props.headings.map((heading, index) => (
          <th className={styles.tableHead} key={`head-cell-${index}`}>{heading}</th>
        ))}
      </tr>
    </thead>
  )
}

function TableRow (props) {
  const { name, selected, onChange, row } = props
  const { cells, key, label, pending } = row
  let className = styles.tableRow
  if (selected) className += ' ' + styles.selected
  if (pending) className += ' ' + styles.pending
  return (
    <tr key={key} className={className}>
      <td className={styles.cell}>
        {!props.readOnly && <input
          type='checkbox'
          name={name}
          value={key}
          id={key}
          checked={selected}
          onChange={onChange}
          aria-label={label} />}
      </td>
      {cells.map((cell, index) => (
        <td className={styles.cell} key={`${key}-cell-${index}`}>
          {cell}
        </td>
      ))}
    </tr>
  )
}

function toggleKey (prev, key) {
  const index = prev.indexOf(key)
  const next = [...prev]
  if (index === -1) {
    next.push(key)
  } else {
    next.splice(index, 1)
  }

  return next
}

SelectableTable.propTypes = {
  formId: PropTypes.string,
  formData: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string)
  }),
  headings: PropTypes.arrayOf(PropTypes.node),
  name: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      cells: PropTypes.arrayOf(PropTypes.node)
    })),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool
}

SelectableTable.defaultProps = {
  headings: [],
  rows: [],
  formData: { value: [] },
  onChange: () => {}
}

module.exports = {
  SelectableTable: connect()(formIdConsumer(SelectableTable)),
  TableHead,
  TableRow
}

'use strict'

const Link = require('@npm/spiferack/link')
const React = require('react')

const styles = require('../../styles/forms.css')

module.exports = function PasswordCriteria (props) {
  const msg = props.message || 'Your password should be at least 10 characters and must meet our '
  return (
    <p className={`${props.className || ''}`}>
      {msg} <Link><a className={styles.emphasis} style={{ fontWeight: 'unset' }} target='_blank' href='https://docs.npmjs.com/creating-a-strong-password' aria-label={`${msg} password guidelines.`}>password guidelines</a></Link>{props.message ? '' : '.'}
    </p>
  )
}

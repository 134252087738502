'use strict'

const Link = require('@npm/spiferack/link')
const PropTypes = require('prop-types')
const React = require('react')
const UserIcon = require('../icons/user')
const CogIcon = require('../icons/cog')
const CreditCardIcon = require('../icons/credit-card')
const CoinsIcon = require('../icons/coins')
const BoxesIcon = require('../icons/boxes')
const styles = require('./sidebar.css')

class SettingsSidebar extends React.PureComponent {
  render () {
    const { memberships = { objects: [] }, user, scope } = this.props
    const hasMemberships = !!memberships.objects.length
    const scopeName = scope.parent.name

    return (
      <aside className={styles.sidebar}>
        <div className={styles.sticky}>
          <ul className={styles.list}>
            <li className={`${styles.listItem}`}>
              <Link>
                <a href={`/~${user.name}`} className={styles.link}>
                  <UserIcon /> Profile
                </a>
              </Link>
            </li>
            <li className={`${styles.listItem}`}>
              <Link>
                <a href={`/settings/${user.name}/packages`} className={styles.link}>
                  <BoxesIcon /> Packages
                </a>
              </Link>
            </li>
            <li className={`${styles.listItem}`}>
              <Link>
                <a href={`/settings/${user.name}/profile`} className={styles.link}>
                  <CogIcon /> Account
                </a>
              </Link>
            </li>
            <li className={`${styles.listItem}`}>
              <Link>
                <a href={`/settings/${user.name}/billing`} className={styles.link}>
                  <CreditCardIcon /> Billing Info
                </a>
              </Link>
            </li>
            <li className={`${styles.listItem}`}>
              <Link>
                <a href={`/settings/${user.name}/tokens`} className={styles.link}>
                  <CoinsIcon /> Access Tokens
                </a>
              </Link>
            </li>
          </ul>
          <h2 className={styles.header}>
            <span id='organizationsHeader'>Organizations</span>
            <Link>
              <a href='/org/create' title='Create New Organization' aria-label='Create New Organization' className={styles.addLink}>
                +
              </a>
            </Link>
          </h2>

          {
            hasMemberships
              ? <ul aria-labelledby='organizationsHeader' className={styles.list}>
                {memberships.objects.map(uo => {
                  const active = uo.org.name === scopeName
                  return <li className={`${styles.listItem} ${active ? styles.active : ''}`} key={uo.org.name}>
                    <Link>
                      <a href={`/settings/${uo.org.name}/packages`} className={styles.link}>
                        <span /><span>{uo.org.name}</span>
                      </a>
                    </Link>
                  </li>
                })}
              </ul>
              : <p>None</p>
          }
        </div>
      </aside>
    )
  }
}

SettingsSidebar.propTypes = {
  memberships: PropTypes.shape({
    total: PropTypes.number,
    objects: PropTypes.array.isRequired
  }).isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
}

module.exports = SettingsSidebar

const React = require('react')
const PropTypes = require('prop-types')
const types = require('../../types')
const Input = require('./semi-controlled')
const formIdConsumer = require('./form-id-consumer')
const connect = require('../connect')
const forms = require('../../styles/forms.css')
const { a11yOnly } = require('../../styles/global.css')

class GenericInput extends React.PureComponent {
  onChange (ev) {
    const { name, formId } = this.props
    this.props.dispatch({
      type: 'FORM_CHANGE',
      name,
      formId,
      value: ev.target.value
    })
    this.props.onChange && this.props.onChange(ev)
  }

  componentDidMount () {
    if (this.props.value || (this.refs.input.value && this.refs.input.value !== '')) { return }
    const { name, formId } = this.props
    this.props.dispatch({
      type: 'FORM_VALIDITY_CHECK',
      name,
      formId,
      errorMessage: null
    })
  }

  render () {
    const {
      a11yOnlyLabel,
      autoComplete,
      className,
      formData,
      icon,
      label,
      name,
      type,
      autoFocus,
      onBlur,
      onFocus,
      placeholder,
      inline,
      readonly,
      required,
      minLength,
      maxLength,
      disabled,
      inputMode,
      initialValue
    } = this.props
    const { value = initialValue, errorMessage } = formData
    const valid = !errorMessage
    const id = this.props.formId + '_' + name

    const containerClass = className + (inline ? ` ${forms.inline}` : '')
    const inputClass = inline ? forms.textInputInline : forms.textInput
    const labelClass = inline ? forms.labelInline : forms.label
    const errorClass = inline ? forms.errorMessageInline : forms.errorMessage
    return (
      <div className={`${containerClass} ${icon ? forms.inputHasIcon : ''}`}>
        {label && <label className={`${labelClass} ${a11yOnlyLabel ? a11yOnly : ''}`} htmlFor={id}>{label}</label>}
        {icon
          ? <span className={forms.icon}>{icon}</span>
          : null
        }
        <Input
          autoFocus={autoFocus}
          id={id}
          aria-label={this.props['aria-label']}
          aria-required={!!this.props['aria-required']}
          ref='input'
          type={type}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          autoComplete={autoComplete}
          className={`${inputClass}  ${valid ? '' : forms.invalid}`}
          onChange={ev => this.onChange(ev)}
          onBlur={onBlur}
          onFocus={onFocus}
          name={name}
          value={value}
          readOnly={readonly}
          placeholder={placeholder}
          disabled={disabled}
          inputMode={inputMode} />
        {errorMessage &&
          <label role='alert' htmlFor={id} className={errorClass}>
            {errorMessage}
          </label>
        }
      </div>
    )
  }
}

GenericInput.propTypes = {
  formId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  formData: types.formDatum.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  inline: PropTypes.bool,
  placeholder: PropTypes.string,
  a11yOnlylabel: PropTypes.bool,
  autoFocus: PropTypes.bool
}

GenericInput.defaultProps = {
  formData: {
    value: ''
  },
  type: 'text',
  className: '',
  inline: false,
  placeholder: '',
  a11yOnlyLabel: false,
  required: false,
  autoFocus: false
}

module.exports = connect()(formIdConsumer(GenericInput))

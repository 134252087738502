'use strict'
const React = require('react')
const propTypes = require('prop-types')
const styles = require('./notifications.css')
const Notification = require('./notification')

function NotificationContainer ({ notifications }) {
  return <div className={styles.container}><ul className={styles.notification_list} aria-live='polite'>
    {
      notifications.map(notification => {
        return <Notification {...notification} key={notification.id} />
      })
    }
  </ul></div>
}

NotificationContainer.propTypes = {
  notifications: propTypes.arrayOf(propTypes.object).isRequired
}

module.exports = NotificationContainer

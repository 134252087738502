
  const __registry__ = require('../../../spiferack/client/index.js')
  'use strict';

var React = require('react');
var Link = require('@npm/spiferack/link');
var forms = require('../../styles/forms.css');

module.exports = {
  WebAuthnLoginFooter: WebAuthnLoginFooter,
  TotpLoginFooter: TotpLoginFooter,
  AccountRecoverySupportFooter: AccountRecoverySupportFooter,
  WebAuthnEscalateFooter: WebAuthnEscalateFooter,
  TotpEscalateFooter: TotpEscalateFooter,
  AccountRecoverySkipEmailVerification: AccountRecoverySkipEmailVerification,
  PasswordEscalateFooter: PasswordEscalateFooter,
  SignupFooter: SignupFooter
};

function SignupFooter(props) {
  var next = props.next;

  return React.createElement(
    'div',
    null,
    React.createElement(FooterTitle, { className: forms.signUpPageFooter, text: 'Already have an account?' }),
    React.createElement(FooterLink, { url: '/login/' + next, text: 'Sign In' })
  );
}

function WebAuthnLoginFooter(props) {
  var hasTotp = props.hasTotp,
      totpUrl = props.totpUrl,
      recoveryCodeUrl = props.recoveryCodeUrl;

  return React.createElement(
    'div',
    null,
    hasTotp || recoveryCodeUrl ? React.createElement(FooterTitle, { text: 'Unable to verify with your security key?' }) : null,
    hasTotp && React.createElement(FooterLink, { url: totpUrl, text: 'Enter two-factor authentication code' }),
    recoveryCodeUrl ? React.createElement(FooterLink, { url: recoveryCodeUrl, text: 'Use a recovery code or request a reset' }) : null
  );
}

function TotpLoginFooter(props) {
  var hasWebAuthnDevices = props.hasWebAuthnDevices,
      webAuthnUrl = props.webAuthnUrl,
      recoveryCodeUrl = props.recoveryCodeUrl;

  return React.createElement(
    'div',
    null,
    hasWebAuthnDevices || recoveryCodeUrl ? React.createElement(FooterTitle, { text: 'Having problems?' }) : null,
    hasWebAuthnDevices && React.createElement(FooterLink, { url: webAuthnUrl, text: 'Use your security key' }),
    recoveryCodeUrl ? React.createElement(FooterLink, { url: recoveryCodeUrl, text: 'Use a recovery code or request a reset' }) : null
  );
}

// TODO need to change link for recovery contact page while integration
function AccountRecoverySupportFooter(props) {
  var accountRecoveryKeyword = props.accountRecoveryKeyword,
      _props$isAutomatedRec = props.isAutomatedRecoveryEnabled,
      isAutomatedRecoveryEnabled = _props$isAutomatedRec === undefined ? false : _props$isAutomatedRec;

  var recoveryUrl = (isAutomatedRecoveryEnabled ? '/recovery-support/message/' : '/recovery-support/') + accountRecoveryKeyword;
  return React.createElement(
    'div',
    null,
    React.createElement(FooterTitle, { text: 'Locked out?' }),
    React.createElement(FooterLink, { url: '' + recoveryUrl, text: 'Try recovering your account' })
  );
}

function AccountRecoverySkipEmailVerification(props) {
  return React.createElement(
    'div',
    null,
    React.createElement(FooterTitle, { text: 'Having trouble with your email?' }),
    React.createElement(FooterLink, { url: '/recovery-support/skip-email-flow', text: 'Skip email verification' })
  );
}

function WebAuthnEscalateFooter(props) {
  var hasTotp = props.hasTotp,
      setEscalateType = props.setEscalateType;

  return React.createElement(
    'div',
    null,
    React.createElement(FooterTitle, { text: 'Unable to verify with your security key?' }),
    hasTotp && React.createElement(FooterButton, { setEscalateType: setEscalateType, escalateType: 'totp', text: 'Enter two-factor authentication code' }),
    React.createElement(FooterButton, { setEscalateType: setEscalateType, escalateType: 'password', text: 'Use password' })
  );
}

function TotpEscalateFooter(props) {
  var hasWebAuthnDevices = props.hasWebAuthnDevices,
      setEscalateType = props.setEscalateType;

  return React.createElement(
    'div',
    null,
    React.createElement(FooterTitle, { text: 'Having problems?' }),
    hasWebAuthnDevices && React.createElement(FooterButton, { setEscalateType: setEscalateType, escalateType: 'webauthn', text: 'Use your security key' }),
    React.createElement(FooterButton, { setEscalateType: setEscalateType, escalateType: 'password', text: 'Use password' })
  );
}

function PasswordEscalateFooter(props) {
  var hasWebAuthnDevices = props.hasWebAuthnDevices,
      hasTotp = props.hasTotp,
      setEscalateType = props.setEscalateType;

  if (!hasWebAuthnDevices && !hasTotp) {
    return null;
  }
  return React.createElement(
    'div',
    null,
    React.createElement(FooterTitle, { text: 'Having problems?' }),
    hasWebAuthnDevices && React.createElement(FooterButton, { setEscalateType: setEscalateType, escalateType: 'webauthn', text: 'Use your security key' }),
    hasTotp && React.createElement(FooterButton, { setEscalateType: setEscalateType, escalateType: 'totp', text: 'Enter two-factor authentication code' })
  );
}

function FooterLink(props) {
  return React.createElement(
    Link,
    null,
    React.createElement(
      'a',
      { href: props.url, className: forms.link },
      props.text
    )
  );
}

function FooterTitle(props) {
  return React.createElement(
    'p',
    { className: 'tc ' + props.className },
    props.text
  );
}

function FooterButton(props) {
  return React.createElement(
    'button',
    { onClick: function onClick() {
        return props.setEscalateType(props.escalateType);
      }, className: forms.buttonLink },
    props.text
  );
}
  let __hot__
  
  __registry__.register('auth/liminal-login-footer', module.exports, __hot__)
  
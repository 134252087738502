
  const __registry__ = require('../../../../spiferack/client/index.js')
  'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var React = require('react');
var connect = require('../../../components/connect');
var formStyles = require('../../../../shared/styles/forms.css');

var _require = require('./constants'),
    SELECTED_PACKAGES_AND_SCOPES_ALL = _require.SELECTED_PACKAGES_AND_SCOPES_ALL,
    SELECTED_PACKAGES_AND_SCOPES_SOME = _require.SELECTED_PACKAGES_AND_SCOPES_SOME,
    SELECTED_PACKAGES = _require.SELECTED_PACKAGES,
    SELECTED_SCOPES = _require.SELECTED_SCOPES,
    SELECTED_PACKAGES_AND_SCOPES = _require.SELECTED_PACKAGES_AND_SCOPES,
    TOTAL_SELECTED_SCOPES = _require.TOTAL_SELECTED_SCOPES,
    TOTAL_SELECTED_PACKAGES = _require.TOTAL_SELECTED_PACKAGES,
    MAX_ALLOWED_PACKAGES_SELECTED = _require.MAX_ALLOWED_PACKAGES_SELECTED;

var gatStyles = require('../gat.css');

var _require2 = require('@primer/components'),
    SelectMenu = _require2.SelectMenu,
    Button = _require2.Button;

var withThemeProvider = require('../../../components/theme-provider');
var Divider = require('./divider');
var DropdownIcon = require('../../../components/icons/dropdown-caret');
var SelectedItems = require('./selected-items');

var PackagesAndScopesInput = function (_React$PureComponent) {
  _inherits(PackagesAndScopesInput, _React$PureComponent);

  function PackagesAndScopesInput(props) {
    _classCallCheck(this, PackagesAndScopesInput);

    var _this = _possibleConstructorReturn(this, (PackagesAndScopesInput.__proto__ || Object.getPrototypeOf(PackagesAndScopesInput)).call(this, props));

    _this.setSelectedPackagesAndScopes = _this.setSelectedPackagesAndScopes.bind(_this);
    _this.onScopeSelect = _this.onScopeSelect.bind(_this);
    _this.onPackageSelect = _this.onPackageSelect.bind(_this);
    _this.onScopeDeselect = _this.onScopeDeselect.bind(_this);
    _this.onPackageDeselect = _this.onPackageDeselect.bind(_this);

    _this.state = {
      searchText: '',
      selectedPackagesAndScopes: null,
      availableScopes: props.allScopes,
      availablePackages: props.allPackages,
      selectedScopes: [],
      selectedPackages: []
    };
    return _this;
  }

  _createClass(PackagesAndScopesInput, [{
    key: 'setSelectedPackagesAndScopes',
    value: function setSelectedPackagesAndScopes(option) {
      this.props.onInputChange(SELECTED_PACKAGES_AND_SCOPES, option);
      this.setState({
        selectedPackagesAndScopes: option
      });
      var _props = this.props,
          formId = _props.formId,
          name = _props.name;

      this.props.dispatch({
        type: 'FORM_CHANGE',
        name: name,
        formId: formId,
        value: option
      });
      // Unset selected packages and scopes in form data if option is 'All packages'.
      // A set of scopes and packages is allowed to be selected only with the  option 'Only select packages and scopes'.
      if (option === SELECTED_PACKAGES_AND_SCOPES_ALL) {
        this.props.dispatch({
          type: 'FORM_CHANGE',
          name: SELECTED_PACKAGES,
          formId: formId,
          value: []
        });
        this.props.dispatch({
          type: 'FORM_CHANGE',
          name: SELECTED_SCOPES,
          formId: formId,
          value: []
        });
        this.props.onInputChange(TOTAL_SELECTED_SCOPES, 0);
        this.props.onInputChange(TOTAL_SELECTED_PACKAGES, 0);
      }
    }
  }, {
    key: 'onScopeSelect',
    value: function onScopeSelect(scope) {
      var selectedScopes = [].concat(_toConsumableArray(this.state.selectedScopes), [scope]);
      var availableScopes = this.state.availableScopes.filter(function (s) {
        return s !== scope;
      });
      this.setState({
        selectedScopes: selectedScopes,
        availableScopes: availableScopes
      });
      this.props.dispatch({
        type: 'FORM_CHANGE',
        name: SELECTED_SCOPES,
        formId: this.props.formId,
        value: [].concat(_toConsumableArray(selectedScopes))
      });
      this.props.onInputChange(TOTAL_SELECTED_SCOPES, selectedScopes.length);
    }
  }, {
    key: 'onPackageSelect',
    value: function onPackageSelect(pkg) {
      var selectedPackages = [].concat(_toConsumableArray(this.state.selectedPackages), [pkg]);
      var availablePackages = this.state.availablePackages.filter(function (s) {
        return s !== pkg;
      });
      this.setState({
        selectedPackages: selectedPackages,
        availablePackages: availablePackages
      });
      this.props.dispatch({
        type: 'FORM_CHANGE',
        name: SELECTED_PACKAGES,
        formId: this.props.formId,
        value: [].concat(_toConsumableArray(selectedPackages))
      });
      this.props.onInputChange(TOTAL_SELECTED_PACKAGES, selectedPackages.length);
    }
  }, {
    key: 'onScopeDeselect',
    value: function onScopeDeselect(scope) {
      var selectedScopes = this.state.selectedScopes.filter(function (s) {
        return s !== scope;
      });
      var availableScopes = [].concat(_toConsumableArray(this.state.availableScopes), [scope]);
      this.setState({
        selectedScopes: selectedScopes,
        availableScopes: availableScopes
      });
      this.props.dispatch({
        type: 'FORM_CHANGE',
        name: SELECTED_SCOPES,
        formId: this.props.formId,
        value: [].concat(_toConsumableArray(selectedScopes))
      });
      this.props.onInputChange(TOTAL_SELECTED_SCOPES, selectedScopes.length);
    }
  }, {
    key: 'onPackageDeselect',
    value: function onPackageDeselect(pkg) {
      var selectedPackages = this.state.selectedPackages.filter(function (s) {
        return s !== pkg;
      });
      var availablePackages = [].concat(_toConsumableArray(this.state.availablePackages), [pkg]);
      this.setState({
        selectedPackages: selectedPackages,
        availablePackages: availablePackages
      });
      this.props.dispatch({
        type: 'FORM_CHANGE',
        name: SELECTED_PACKAGES,
        formId: this.props.formId,
        value: [].concat(_toConsumableArray(selectedPackages))
      });
      this.props.onInputChange(TOTAL_SELECTED_PACKAGES, selectedPackages.length);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var name = this.props.name;


      var scopes = this.state.availableScopes.filter(function (s) {
        return s.toLowerCase().includes(_this2.state.searchText.toLowerCase());
      });
      var packages = this.state.availablePackages.filter(function (s) {
        return s.toLowerCase().includes(_this2.state.searchText.toLowerCase());
      });
      var scopesCount = this.state.selectedScopes.length;var pkgsCount = this.state.selectedPackages.length;
      var selectedItemGroups = [{
        label: scopesCount === 1 ? scopesCount + ' scope selected' : scopesCount + ' scopes selected',
        onClick: this.onScopeDeselect.bind(this),
        items: this.state.selectedScopes
      }, {
        label: pkgsCount === 1 ? pkgsCount + ' package selected' : pkgsCount + ' packages selected',
        onClick: this.onPackageDeselect.bind(this),
        items: this.state.selectedPackages
      }];

      return React.createElement(
        'div',
        null,
        React.createElement(
          'div',
          null,
          React.createElement(
            'label',
            { className: formStyles.label },
            'Select packages'
          )
        ),
        React.createElement(
          'div',
          null,
          React.createElement(
            'div',
            { className: gatStyles.radioSelectPackageContainer },
            React.createElement('input', { type: 'radio', name: name, id: SELECTED_PACKAGES_AND_SCOPES_ALL,
              onClick: function onClick() {
                return _this2.setSelectedPackagesAndScopes(SELECTED_PACKAGES_AND_SCOPES_ALL);
              } })
          ),
          React.createElement(
            'div',
            { className: gatStyles.radioSelectPackageLabelContainer },
            React.createElement(
              'label',
              { htmlFor: SELECTED_PACKAGES_AND_SCOPES_ALL, className: gatStyles.radioSelectPackageLabel },
              'All packages'
            ),
            React.createElement(
              'p',
              { className: formStyles.labelBeneath },
              'Applies to current and future packages.'
            )
          )
        ),
        React.createElement(
          'div',
          null,
          React.createElement(
            'div',
            { className: gatStyles.radioSelectPackageContainer },
            React.createElement('input', { type: 'radio', name: name, id: SELECTED_PACKAGES_AND_SCOPES_SOME,
              onClick: function onClick() {
                return _this2.setSelectedPackagesAndScopes(SELECTED_PACKAGES_AND_SCOPES_SOME);
              } })
          ),
          React.createElement(
            'div',
            { className: gatStyles.radioSelectPackageLabelContainer },
            React.createElement(
              'label',
              { htmlFor: SELECTED_PACKAGES_AND_SCOPES_SOME, className: gatStyles.radioSelectPackageLabel },
              'Only select packages and scopes'
            ),
            React.createElement(
              'p',
              { className: formStyles.labelBeneath },
              'Select at least one. Max ',
              MAX_ALLOWED_PACKAGES_SELECTED,
              '.'
            ),
            this.state.selectedPackagesAndScopes === SELECTED_PACKAGES_AND_SCOPES_SOME && React.createElement(
              'div',
              null,
              this.state.selectedPackages.length + this.state.selectedScopes.length < MAX_ALLOWED_PACKAGES_SELECTED && React.createElement(
                SelectMenu,
                null,
                React.createElement(
                  Button,
                  { as: 'summary', id: gatStyles.dropdownButtonPackages },
                  'Select packages and scopes ',
                  React.createElement(
                    'span',
                    { className: 'ml2' },
                    React.createElement(DropdownIcon, null)
                  )
                ),
                React.createElement(
                  SelectMenu.Modal,
                  null,
                  React.createElement(SelectMenu.Filter, { value: this.state.searchText, onChange: function onChange(ev) {
                      return _this2.setState({ searchText: ev.target.value });
                    } }),
                  React.createElement(
                    SelectMenu.List,
                    null,
                    scopes.length > 0 && React.createElement(
                      SelectMenu.Divider,
                      { className: gatStyles.selectMenuDivider },
                      'Scopes'
                    ),
                    scopes.length > 0 && scopes.map(function (scope) {
                      return React.createElement(
                        SelectMenu.Item,
                        { className: gatStyles.selectItem, as: 'button', key: scope, onClick: function onClick() {
                            return _this2.onScopeSelect(scope);
                          } },
                        scope
                      );
                    }),
                    packages.length > 0 && React.createElement(Divider, { classNames: gatStyles.selectListGroupDivider }),
                    packages.length > 0 && React.createElement(
                      SelectMenu.Divider,
                      { className: gatStyles.selectMenuDivider },
                      'Packages'
                    ),
                    packages.length > 0 && packages.map(function (pkg) {
                      return React.createElement(
                        SelectMenu.Item,
                        { className: gatStyles.selectItem, as: 'button', key: pkg, onClick: function onClick() {
                            return _this2.onPackageSelect(pkg);
                          } },
                        pkg
                      );
                    })
                  )
                )
              ),
              React.createElement(SelectedItems, { itemGroups: selectedItemGroups })
            )
          )
        )
      );
    }
  }]);

  return PackagesAndScopesInput;
}(React.PureComponent);

module.exports = connect()(withThemeProvider(PackagesAndScopesInput));
  let __hot__
  
  __registry__.register('tokens/gat/packages-and-scopes-input', module.exports, __hot__)
  
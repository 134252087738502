
  const __registry__ = require('../../../spiferack/client/index.js')
  'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var React = require('react');

var WebAuthnLoginForm = require('../../components/auth/webauthn-login-form');

var _require = require('./liminal-login-footer'),
    WebAuthnLoginFooter = _require.WebAuthnLoginFooter,
    WebAuthnEscalateFooter = _require.WebAuthnEscalateFooter;

var LiminalLoginLayout = require('../../components/layouts/liminal-login');
var UserAvatarHeaderComponent = require('../../components/auth/user-avatar-header-component');

var WebAuthnLogin = function (_React$PureComponent) {
  _inherits(WebAuthnLogin, _React$PureComponent);

  function WebAuthnLogin() {
    _classCallCheck(this, WebAuthnLogin);

    return _possibleConstructorReturn(this, (WebAuthnLogin.__proto__ || Object.getPrototypeOf(WebAuthnLogin)).apply(this, arguments));
  }

  _createClass(WebAuthnLogin, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          setEscalateType = _props.setEscalateType,
          action = _props.action,
          hasTotp = _props.hasTotp,
          totpUrl = _props.totpUrl,
          recoveryCodeUrl = _props.recoveryCodeUrl,
          isNewCliLoginFlowEscalation = _props.isNewCliLoginFlowEscalation,
          isNewPublishAuthEscalation = _props.isNewPublishAuthEscalation,
          isTfaEscalate = _props.isTfaEscalate;


      var user = isNewPublishAuthEscalation ? this.props.tokenUser : this.props.user;

      var Header = (isNewCliLoginFlowEscalation || isNewPublishAuthEscalation) && user ? React.createElement(UserAvatarHeaderComponent, _extends({ tokenUser: user }, this.props)) : 'Two-Factor Authentication';

      var Footer = void 0;
      if (isTfaEscalate) {
        Footer = React.createElement(WebAuthnEscalateFooter, { hasTotp: hasTotp, setEscalateType: setEscalateType, action: action });
      } else if (isNewPublishAuthEscalation || isNewCliLoginFlowEscalation) {
        Footer = React.createElement(WebAuthnLoginFooter, { hasTotp: hasTotp, totpUrl: totpUrl });
      } else {
        Footer = React.createElement(WebAuthnLoginFooter, { hasTotp: hasTotp, totpUrl: totpUrl, recoveryCodeUrl: recoveryCodeUrl });
      }
      return React.createElement(
        LiminalLoginLayout,
        { Footer: Footer, Header: Header },
        React.createElement(WebAuthnLoginForm, this.props)
      );
    }
  }]);

  return WebAuthnLogin;
}(React.PureComponent);

WebAuthnLogin.layout = 'none';

module.exports = WebAuthnLogin;
  let __hot__
  
  __registry__.register('auth/webauthn-login', module.exports, __hot__)
  

  const __registry__ = require('../../../../spiferack/client/index.js')
  'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var React = require('react');
var PropTypes = require('prop-types');
var formStyles = require('../../../../shared/styles/forms.css');
var types = require('../../../types');
var formIdConsumer = require('../../../components/inputs/form-id-consumer');
var connect = require('../../../components/connect');
var gatStyles = require('../gat.css');

var _require = require('./constants'),
    LINK_CIDR_NOTATION = _require.LINK_CIDR_NOTATION;

var isCidr4 = require('is-cidr').v4;

var AllowedIPRangesInput = function (_React$PureComponent) {
  _inherits(AllowedIPRangesInput, _React$PureComponent);

  function AllowedIPRangesInput() {
    _classCallCheck(this, AllowedIPRangesInput);

    return _possibleConstructorReturn(this, (AllowedIPRangesInput.__proto__ || Object.getPrototypeOf(AllowedIPRangesInput)).apply(this, arguments));
  }

  _createClass(AllowedIPRangesInput, [{
    key: 'onChange',
    value: function onChange(ev, index) {
      var _props = this.props,
          name = _props.name,
          formId = _props.formId;
      var value = this.props.formData.value;

      var ipRanges = value ? [].concat(_toConsumableArray(value.slice(0, index)), [ev.target.value], _toConsumableArray(value.slice(index + 1))) : [ev.target.value];
      this.props.dispatch({
        type: 'FORM_CHANGE',
        name: name,
        formId: formId,
        value: ipRanges
      });
    }
  }, {
    key: 'onAddIPRangeInput',
    value: function onAddIPRangeInput() {
      var _props2 = this.props,
          name = _props2.name,
          formId = _props2.formId;
      var value = this.props.formData.value;

      var ipRanges = value ? [].concat(_toConsumableArray(value), ['']) : [''];
      this.props.dispatch({
        type: 'FORM_CHANGE',
        name: name,
        formId: formId,
        value: ipRanges
      });
    }
  }, {
    key: 'onRemoveIPRangeInput',
    value: function onRemoveIPRangeInput(index) {
      var _props3 = this.props,
          name = _props3.name,
          formId = _props3.formId;
      var value = this.props.formData.value;

      this.props.dispatch({
        type: 'FORM_CHANGE',
        name: name,
        formId: formId,
        value: [].concat(_toConsumableArray(value.slice(0, index)), _toConsumableArray(value.slice(index + 1)))
      });
    }

    // Need to have a valid check for each entry for the UI
    // as only invalid entries should be highlighted with red border.

  }, {
    key: 'isValidEntry',
    value: function isValidEntry(ipRange) {
      if (ipRange === undefined || ipRange === '') {
        return true;
      }
      return isCidr4(ipRange);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props4 = this.props,
          formId = _props4.formId,
          formData = _props4.formData,
          name = _props4.name;
      var value = formData.value,
          errorMessage = formData.errorMessage;

      var ipRanges = value || [''];

      return React.createElement(
        'div',
        null,
        React.createElement(
          'div',
          null,
          React.createElement(
            'label',
            { htmlFor: name, className: gatStyles.inputLabel },
            'Allowed IP ranges',
            React.createElement(
              'span',
              { className: gatStyles.inputLabelOptional },
              ' (optional)'
            )
          ),
          React.createElement(
            'caption',
            { className: gatStyles.inputCaption },
            'Must be valid\xA0',
            React.createElement(
              'a',
              {
                href: LINK_CIDR_NOTATION,
                className: gatStyles.linkAllowedIPRangesCIDRNotation },
              'CIDR notation'
            ),
            '.'
          )
        ),
        React.createElement(
          'div',
          null,
          ipRanges.map(function (ipRange, index) {
            return React.createElement(
              'div',
              { key: formId + '_' + name + '_' + index },
              React.createElement('input', {
                type: 'text',
                name: formId + '_' + name + '_' + index,
                id: formId + '_' + name + '_' + index,
                value: ipRange,
                onChange: function onChange(ev) {
                  return _this2.onChange(ev, index);
                },
                className: _this2.isValidEntry(ipRange) ? '' + gatStyles.inputIPRangeValidCIDR : '' + gatStyles.inputIPRangeInvalidCIDR }),
              index > 0 && React.createElement(
                'button',
                {
                  type: 'button',
                  onClick: function onClick() {
                    return _this2.onRemoveIPRangeInput(index);
                  },
                  className: gatStyles.btnRemoveIPRange },
                'Remove'
              )
            );
          })
        ),
        React.createElement(
          'button',
          {
            type: 'button',
            onClick: function onClick() {
              return _this2.onAddIPRangeInput();
            },
            className: formStyles.buttonGradient },
          'Add IP Range'
        ),
        errorMessage && React.createElement(
          'div',
          null,
          React.createElement(
            'label',
            { role: 'alert', className: formStyles.errorMessage },
            errorMessage
          )
        )
      );
    }
  }]);

  return AllowedIPRangesInput;
}(React.PureComponent);

AllowedIPRangesInput.propTypes = {
  formId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  formData: types.formDatum.isRequired
};

AllowedIPRangesInput.defaultProps = {
  formData: {
    value: ['']
  }
};

module.exports = connect()(formIdConsumer(AllowedIPRangesInput));
  let __hot__
  
  __registry__.register('tokens/gat/allowed-ip-ranges-input', module.exports, __hot__)
  

  const __registry__ = require('../../../spiferack/client/index.js')
  'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var React = require('react');
var OTPLogin = require('./otp');
var Password = require('./password');
var WebAuthnLogin = require('./webauthn-login');

var Escalate = function (_React$PureComponent) {
  _inherits(Escalate, _React$PureComponent);

  function Escalate(props) {
    _classCallCheck(this, Escalate);

    var _this = _possibleConstructorReturn(this, (Escalate.__proto__ || Object.getPrototypeOf(Escalate)).call(this, props));

    _this.setEscalateType = function (newEscalateType) {
      _this.setState({ escalateType: newEscalateType });
    };

    _this.state = {
      escalateType: _this.props.escalateType
    };
    _this.handler = _this.setEscalateType.bind(_this);
    return _this;
  }

  _createClass(Escalate, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          hasTotp = _props.hasTotp,
          hasWebAuthnDevices = _props.hasWebAuthnDevices,
          action = _props.action,
          publicKeyCredentialRequestOptions = _props.publicKeyCredentialRequestOptions,
          csrftoken = _props.csrftoken,
          originalUrl = _props.originalUrl,
          _props$errorCount = _props.errorCount,
          errorCount = _props$errorCount === undefined ? 0 : _props$errorCount;


      switch (this.state.escalateType) {
        case 'webauthn':
          return React.createElement(WebAuthnLogin, {
            setEscalateType: this.handler,
            hasTotp: hasTotp,
            isTfaEscalate: true,
            publicKeyCredentialRequestOptions: publicKeyCredentialRequestOptions,
            action: action,
            originalUrl: originalUrl,
            csrftoken: csrftoken,
            errorCount: errorCount });
        case 'totp':
          return React.createElement(OTPLogin, {
            setEscalateType: this.handler,
            hasWebAuthnDevices: hasWebAuthnDevices,
            isTfaEscalate: true,
            action: action,
            originalUrl: originalUrl,
            csrftoken: csrftoken });
        default:
          return React.createElement(Password, {
            setEscalateType: this.handler,
            hasWebAuthnDevices: hasWebAuthnDevices,
            hasTotp: hasTotp,
            action: action,
            originalUrl: originalUrl,
            csrftoken: csrftoken });
      }
    }
  }]);

  return Escalate;
}(React.PureComponent);

Escalate.layout = 'none';
module.exports = Escalate;
  let __hot__
  
  __registry__.register('auth/escalate', module.exports, __hot__)
  

  const __registry__ = require('../../../../spiferack/client/index.js')
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var React = require('react');
var PropTypes = require('prop-types');
var types = require('../../../types');
var Input = require('../../../components/inputs/semi-controlled');
var connect = require('../../../components/connect');
var formStyles = require('../../../../shared/styles/forms.css');
var gatStyles = require('../gat.css');
var formIdConsumer = require('../../../components/inputs/form-id-consumer');

var GATInput = function (_React$PureComponent) {
  _inherits(GATInput, _React$PureComponent);

  function GATInput() {
    _classCallCheck(this, GATInput);

    return _possibleConstructorReturn(this, (GATInput.__proto__ || Object.getPrototypeOf(GATInput)).apply(this, arguments));
  }

  _createClass(GATInput, [{
    key: 'onChange',
    value: function onChange(ev) {
      var _props = this.props,
          name = _props.name,
          formId = _props.formId;

      this.props.dispatch({
        type: 'FORM_CHANGE',
        name: name,
        formId: formId,
        value: ev.target.value
      });
      this.props.onInputChange && this.props.onInputChange(ev);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          id = _props2.id,
          inputClassName = _props2.inputClassName,
          formData = _props2.formData,
          label = _props2.label,
          labelBeneath = _props2.labelBeneath,
          name = _props2.name,
          type = _props2.type,
          autoFocus = _props2.autoFocus,
          onBlur = _props2.onBlur,
          onFocus = _props2.onFocus,
          required = _props2.required,
          disabled = _props2.disabled,
          inputMode = _props2.inputMode,
          initialValue = _props2.initialValue,
          inputSuffixLabel = _props2.inputSuffixLabel,
          rest = _objectWithoutProperties(_props2, ['id', 'inputClassName', 'formData', 'label', 'labelBeneath', 'name', 'type', 'autoFocus', 'onBlur', 'onFocus', 'required', 'disabled', 'inputMode', 'initialValue', 'inputSuffixLabel']);

      var _formData$value = formData.value,
          value = _formData$value === undefined ? initialValue : _formData$value,
          errorMessage = formData.errorMessage;

      var valid = !errorMessage;
      var inputId = id || this.props.formId + '_' + name;
      var inputClass = inputClassName + ' ' + gatStyles.textInput;
      var errorClass = formStyles.errorMessage;
      return React.createElement(
        'div',
        { className: gatStyles.inputContainer },
        React.createElement(
          'label',
          { className: gatStyles.inputLabel, htmlFor: id },
          label
        ),
        React.createElement(
          'caption',
          { className: gatStyles.inputCaption },
          labelBeneath
        ),
        React.createElement(
          'div',
          null,
          React.createElement(Input, _extends({
            autoFocus: autoFocus,
            id: inputId,
            type: type,
            required: required,
            className: inputClass + '  ' + (valid ? '' : formStyles.invalid),
            onChange: function onChange(ev) {
              return _this2.onChange(ev);
            },
            onBlur: onBlur,
            onFocus: onFocus,
            name: name,
            value: value,
            disabled: disabled,
            inputMode: inputMode
          }, rest)),
          inputSuffixLabel && inputSuffixLabel,
          errorMessage && React.createElement(
            'div',
            null,
            React.createElement(
              'label',
              { role: 'alert', htmlFor: inputId, className: errorClass },
              errorMessage
            )
          )
        )
      );
    }
  }]);

  return GATInput;
}(React.PureComponent);

GATInput.propTypes = {
  formId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  formData: types.formDatum.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool
};

GATInput.defaultProps = {
  type: 'text',
  required: false,
  autoFocus: false
};

module.exports = connect()(formIdConsumer(GATInput));
  let __hot__
  
  __registry__.register('tokens/gat/gat-input', module.exports, __hot__)
  

  const __registry__ = require('../../../spiferack/client/index.js')
  'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _require = require('react-helmet'),
    Helmet = _require.Helmet;

var PropTypes = require('prop-types');
var React = require('react');

var PasswordInput = require('../../components/inputs/password');
var GenericForm = require('../../components/forms/generic');
var HiddenInput = require('../../components/inputs/hidden');
var styles = require('./auth.css');
var LiminalLoginLayout = require('../../components/layouts/liminal-login');

var _require2 = require('./liminal-login-footer'),
    PasswordEscalateFooter = _require2.PasswordEscalateFooter;

var Password = function (_React$Component) {
  _inherits(Password, _React$Component);

  function Password() {
    _classCallCheck(this, Password);

    return _possibleConstructorReturn(this, (Password.__proto__ || Object.getPrototypeOf(Password)).apply(this, arguments));
  }

  _createClass(Password, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          action = _props.action,
          _props$formData = _props.formData,
          formData = _props$formData === undefined ? {} : _props$formData,
          hasWebAuthnDevices = _props.hasWebAuthnDevices,
          hasTotp = _props.hasTotp,
          setEscalateType = _props.setEscalateType,
          originalUrl = _props.originalUrl;


      var Footer = React.createElement(PasswordEscalateFooter, {
        hasWebAuthnDevices: hasWebAuthnDevices,
        hasTotp: hasTotp,
        setEscalateType: setEscalateType });

      return React.createElement(
        LiminalLoginLayout,
        { Footer: Footer },
        React.createElement(
          'div',
          { className: styles.login },
          React.createElement(
            Helmet,
            null,
            React.createElement(
              'title',
              null,
              'npm | Confirm Password to Continue'
            )
          ),
          React.createElement(
            GenericForm,
            {
              key: 'form',
              action: action,
              formId: 'escalate',
              formData: formData,
              onSubmit: function onSubmit() {} // noop to get default form submit
              , buttonText: 'Confirm password to continue',
              className: 'ma0' },
            React.createElement(HiddenInput, { formId: 'escalate-type', name: 'formName', value: 'password', formData: formData }),
            React.createElement(HiddenInput, { formId: 'originalUrl', name: 'originalUrl', value: originalUrl, formData: formData }),
            React.createElement(PasswordInput, {
              formData: formData,
              minLength: '0',
              name: 'escalated_password'
            })
          )
        )
      );
    }
  }]);

  return Password;
}(React.Component);

Password.layout = 'none';

Password.propTypes = {
  action: PropTypes.string.isRequired
};

module.exports = Password;
  let __hot__
  
  __registry__.register('auth/password', module.exports, __hot__)
  
'use strict'

const React = require('react')
const styles = require('./transitions.css')
const { CSSTransitionGroup } = require('react-transition-group')

module.exports = function FadeIn ({ children }) {
  let child
  if (children) {
    child = React.Children.only(children)
    if (!child.key) {
      child = React.cloneElement(child, { key: 'item' })
    }
  }
  return <CSSTransitionGroup
    transitionName={{
      appear: styles.fadeIn,
      appearActive: styles.fadeInActive,
      enter: styles.fadeIn,
      enterActive: styles.fadeInActive,
      leave: styles.fadeOut,
      leaveActive: styles.fadeOutActive
    }}
    transitionEnter
    transitionEnterTimeout={200}
    transitionLeave
    transitionLeaveTimeout={200}>
    {child || <div key='empty' />}
  </CSSTransitionGroup>
}


  const __registry__ = require('../../../../spiferack/client/index.js')
  'use strict';

var React = require('react');
var gatStyles = require('../gat.css');

var _require = require('@primer/octicons-react'),
    XIcon = _require.XIcon;

function SelectedItems(props) {
  var itemGroups = props.itemGroups;


  return React.createElement(
    'div',
    { className: gatStyles.selectedItemsContainer },
    itemGroups.length > 0 && React.createElement(
      'div',
      { className: gatStyles.selectedItemsInnerContainer },
      itemGroups.map(function (_ref) {
        var label = _ref.label,
            _onClick = _ref.onClick,
            items = _ref.items;

        return items.length > 0 && React.createElement(
          'div',
          { key: label },
          React.createElement(
            'div',
            { className: gatStyles.selectedItemsGroupTitleContainer },
            React.createElement(
              'label',
              { className: gatStyles.selectedItemsGroupTitle },
              label
            )
          ),
          items.map(function (item, index) {
            return React.createElement(
              'div',
              { key: label + '_' + item,
                className: index % 2 === 0 ? '' + gatStyles.selectedItemAtEvenPositionContainer : '' + gatStyles.selectedItemAtOddPositionContainer },
              React.createElement(
                'label',
                { className: gatStyles.selectedItemLabel },
                item
              ),
              _onClick && React.createElement(
                'button',
                {
                  type: 'button', onClick: function onClick() {
                    _onClick(item);
                  },
                  className: gatStyles.btnSelectedItemDeselect },
                React.createElement(XIcon, null)
              )
            );
          })
        );
      })
    )
  );
}

module.exports = SelectedItems;
  let __hot__
  
  __registry__.register('tokens/gat/selected-items', module.exports, __hot__)
  
'use strict'
const React = require('react')
const PropTypes = require('prop-types')

class PaginationSummary extends React.PureComponent {
  render () {
    const { page, perPage, total } = this.props
    const start = page * perPage + 1
    const end = Math.min((page + 1) * perPage, total)
    return <span>Rows {start} to {end} of {total}</span>
  }
}

PaginationSummary.defaultProps = {
  page: 0,
  perPage: 10
}

PaginationSummary.propTypes = {
  page: PropTypes.any.isRequired,
  perPage: PropTypes.any.isRequired,
  total: PropTypes.any.isRequired
}

module.exports = PaginationSummary

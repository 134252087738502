
  const __registry__ = require('../../../../spiferack/client/index.js')
  'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var React = require('react');
var connect = require('../../../components/connect');
var formIdConsumer = require('../../../components/inputs/form-id-consumer');
var gatStyles = require('../gat.css');
var formStyles = require('../../../../shared/styles/forms.css');

var _require = require('./constants'),
    SELECTED_ORGS = _require.SELECTED_ORGS,
    TOTAL_SELECTED_ORGS = _require.TOTAL_SELECTED_ORGS;

var OrganizationsInput = function (_React$PureComponent) {
  _inherits(OrganizationsInput, _React$PureComponent);

  function OrganizationsInput(props) {
    _classCallCheck(this, OrganizationsInput);

    var _this = _possibleConstructorReturn(this, (OrganizationsInput.__proto__ || Object.getPrototypeOf(OrganizationsInput)).call(this, props));

    _this.onClickOrg = _this.onToggleOrg.bind(_this);

    var allOrgs = _this.props.allOrgs;

    _this.state = {
      orgs: allOrgs.map(function (org) {
        return { name: org, selected: false };
      })
    };
    return _this;
  }

  _createClass(OrganizationsInput, [{
    key: 'onToggleOrg',
    value: function onToggleOrg(orgName) {
      var orgs = this.state.orgs.map(function (org) {
        if (org.name === orgName) {
          org.selected = !org.selected;
        }
        return org;
      });
      this.setState({ orgs: orgs });
      this.props.dispatch({
        type: 'FORM_CHANGE',
        name: SELECTED_ORGS,
        formId: this.props.formId,
        value: orgs.filter(function (org) {
          return org.selected;
        }).map(function (org) {
          return org.name;
        })
      });
      this.props.onInputChange(TOTAL_SELECTED_ORGS, orgs.filter(function (org) {
        return org.selected;
      }).length);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      return React.createElement(
        'div',
        { className: gatStyles.orgInputContainer },
        React.createElement(
          'div',
          null,
          React.createElement(
            'label',
            { className: formStyles.label },
            'Select organizations'
          )
        ),
        React.createElement(
          'div',
          { className: gatStyles.orgListContainer },
          this.state.orgs.map(function (org) {
            return React.createElement(
              'div',
              { key: org.name, className: gatStyles.orgInputEntryContainer },
              React.createElement('input', { type: 'checkbox', name: org.name, id: org.name, value: org.name,
                onClick: function onClick() {
                  return _this2.onToggleOrg(org.name);
                } }),
              React.createElement(
                'label',
                { className: gatStyles.orgInputLabel },
                org.name
              )
            );
          })
        )
      );
    }
  }]);

  return OrganizationsInput;
}(React.PureComponent);

module.exports = connect()(formIdConsumer(OrganizationsInput));
  let __hot__
  
  __registry__.register('tokens/gat/organizations-input', module.exports, __hot__)
  
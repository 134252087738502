
  const __registry__ = require('../../../../spiferack/client/index.js')
  'use strict';

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _require = require('@primer/components'),
    SelectMenu = _require.SelectMenu,
    Button = _require.Button;

var React = require('react');
var connect = require('../../../components/connect');
var withThemeProvider = require('../../../components/theme-provider');

var _require2 = require('./constants'),
    PERMISSION_NO_ACCESS = _require2.PERMISSION_NO_ACCESS,
    PERMISSION_READ_ONLY = _require2.PERMISSION_READ_ONLY,
    PERMISSION_READ_WRITE = _require2.PERMISSION_READ_WRITE;

var formStyles = require('../../../../shared/styles/forms.css');
var gatStyles = require('../gat.css');
var DropdownIcon = require('../../../components/icons/dropdown-caret');

function PermissionsInput(props) {
  var _React$useState = React.useState(PERMISSION_NO_ACCESS),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      selectedPermission = _React$useState2[0],
      setSelectedPermission = _React$useState2[1];

  var permissions = [PERMISSION_NO_ACCESS, PERMISSION_READ_ONLY, PERMISSION_READ_WRITE];

  function _onClick(permission) {
    var onSelect = props.onSelect,
        formId = props.formId,
        name = props.name;

    setSelectedPermission(permission);
    onSelect && onSelect(permission);
    props.dispatch({
      type: 'FORM_CHANGE',
      name: name,
      formId: formId,
      value: permission
    });
  }

  return React.createElement(
    'div',
    { className: gatStyles.permissionsContainer },
    React.createElement(
      'h3',
      { className: formStyles.label },
      'Permissions'
    ),
    React.createElement(
      SelectMenu,
      null,
      React.createElement(
        Button,
        { as: 'summary', id: gatStyles.dropdownButtonPermissions },
        selectedPermission,
        ' ',
        React.createElement(
          'span',
          { className: 'ml2' },
          React.createElement(DropdownIcon, null)
        )
      ),
      React.createElement(
        SelectMenu.Modal,
        null,
        React.createElement(
          SelectMenu.List,
          null,
          permissions.map(function (permission) {
            return React.createElement(
              SelectMenu.Item,
              {
                selected: selectedPermission === permission,
                as: 'button',
                type: 'button',
                key: permission,
                onClick: function onClick() {
                  return _onClick(permission);
                },
                className: gatStyles.permissionEntry },
              React.createElement(
                'span',
                { className: gatStyles.permissionLabel },
                permission
              )
            );
          })
        )
      )
    )
  );
}

module.exports = connect()(withThemeProvider(PermissionsInput));
  let __hot__
  
  __registry__.register('tokens/gat/permissions-input', module.exports, __hot__)
  
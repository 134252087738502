
  const __registry__ = require('../../../spiferack/client/index.js')
  'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var React = require('react');

var styles = require('../../components/contact/contact.css');

module.exports = function (_React$PureComponent) {
  _inherits(Sidebar, _React$PureComponent);

  function Sidebar() {
    _classCallCheck(this, Sidebar);

    return _possibleConstructorReturn(this, (Sidebar.__proto__ || Object.getPrototypeOf(Sidebar)).apply(this, arguments));
  }

  _createClass(Sidebar, [{
    key: 'render',
    value: function render() {
      return React.createElement(
        'div',
        null,
        React.createElement(
          'section',
          null,
          React.createElement(
            'h3',
            { className: styles.header },
            'npm command-line'
          ),
          React.createElement(
            'ul',
            { className: styles.list },
            React.createElement(
              'li',
              { className: styles.listItem },
              'If you\'re having trouble using the npm command-line interface, or you need help with a project that you\u2019re working on, we recommend that you check out the Software Development board of the ',
              React.createElement(
                'a',
                { className: styles.link, href: 'https://github.community/c/software-development/47' },
                'GitHub Community forums'
              ),
              '.'
            )
          )
        ),
        React.createElement(
          'section',
          null,
          React.createElement(
            'h3',
            { className: styles.header },
            'Giving Feedback'
          ),
          React.createElement(
            'ul',
            { className: styles.list },
            React.createElement(
              'li',
              { className: styles.listItem },
              'If you have suggestions for how we can improve npm please ',
              React.createElement(
                'a',
                { className: styles.link, href: 'https://github.com/npm/feedback/discussions' },
                'open a discussion'
              ),
              ' in our feedback forum.'
            )
          )
        ),
        React.createElement(
          'section',
          null,
          React.createElement(
            'h3',
            { className: styles.header },
            'Press Relations'
          ),
          React.createElement(
            'ul',
            { className: styles.list },
            React.createElement(
              'li',
              { className: styles.listItem },
              'Press inquiries should be addressed to \xA0',
              React.createElement(
                'a',
                { className: styles.link, href: 'mailto:press@npmjs.com', 'aria-describedby': 'press-relations-desc' },
                'press@npmjs.com'
              )
            ),
            React.createElement(
              'p',
              { id: 'press-relations-desc', className: styles.hidden },
              'Press inquiries should be addressed to press@npmjs.com'
            )
          )
        )
      );
    }
  }]);

  return Sidebar;
}(React.PureComponent);
  let __hot__
  
  __registry__.register('contact/sidebar', module.exports, __hot__)
  

  const __registry__ = require('../../../spiferack/client/index.js')
  'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var React = require('react');
var moment = require('moment');
var Router = require('@npm/spiferack/router');

var _require = require('../../styles/global.css'),
    a11yOnly = _require.a11yOnly;

var forms = require('../../styles/forms.css');
var styles = require('../../pages/tfa/list.css');
var Time = require('../../components/time');
var TfaTable = require('../../components/tfa-table/tfa-table');

var SecurityKeyList = function (_React$PureComponent) {
  _inherits(SecurityKeyList, _React$PureComponent);

  function SecurityKeyList(props) {
    _classCallCheck(this, SecurityKeyList);

    var _this = _possibleConstructorReturn(this, (SecurityKeyList.__proto__ || Object.getPrototypeOf(SecurityKeyList)).call(this, props));

    _this.state = {
      deleteInProgress: false,
      deviceCount: props.numWebAuthnDevices,
      errorCount: props.errorCount || 0
    };
    return _this;
  }

  _createClass(SecurityKeyList, [{
    key: 'deleteSecurityKey',
    value: function deleteSecurityKey(ev, deviceId) {
      ev.preventDefault();
      var _props = this.props,
          deleteWebAuthnURL = _props.deleteWebAuthnURL,
          totpEnabled = _props.totpEnabled,
          numWebAuthnDevices = _props.numWebAuthnDevices,
          csrftoken = _props.csrftoken;


      this.setState({ deleteInProgress: true });
      var msg = numWebAuthnDevices === 1 && !totpEnabled ? 'Deleting the last 2FA device will disable 2FA on your account. Are you sure?' : 'Are you sure?';
      var confirm = window.confirm(msg);
      if (!confirm) {
        this.setState({ deleteInProgress: false });
        return;
      }

      return Router.get().submit({
        action: deleteWebAuthnURL,
        method: 'POST',
        data: {
          csrftoken: csrftoken,
          errorCount: this.state.errorCount,
          deviceId: deviceId
        }
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          canDelete = _props2.canDelete,
          list = _props2.list,
          numWebAuthnDevices = _props2.numWebAuthnDevices,
          errorCount = _props2.errorCount;
      var objects = list.objects;


      if (this.state.deviceCount !== numWebAuthnDevices || this.state.errorCount !== errorCount) {
        this.setState({
          deleteInProgress: false,
          deviceCount: numWebAuthnDevices,
          errorCount: errorCount
        });
      }

      var headings = [React.createElement(
        'div',
        null,
        'Security Key'
      ), React.createElement(
        'div',
        null,
        'Created'
      ), React.createElement(
        'div',
        { className: a11yOnly },
        'Remove'
      )];

      var rows = objects.map(function (obj) {
        var deviceId = obj.deviceId,
            nickname = obj.nickname,
            created = obj.created;

        return {
          cells: [React.createElement(
            'div',
            null,
            React.createElement(
              'span',
              null,
              nickname
            )
          ), React.createElement(Time, { at: {
              ts: Number(new Date(created)),
              rel: moment(new Date(created)).fromNow()
            } }), React.createElement(
            'div',
            null,
            React.createElement(
              'button',
              { 'aria-label': 'Delete security key ' + nickname, className: forms.deleteButton, disabled: !canDelete.webAuthn || _this2.state.deleteInProgress, onClick: function onClick(ev) {
                  return _this2.deleteSecurityKey(ev, deviceId);
                } },
              '\xD7'
            )
          )],
          key: deviceId.toString(),
          label: 'label for ' + nickname
        };
      });

      return React.createElement(
        'div',
        null,
        React.createElement(SecurityKeys, { headings: headings, rows: rows }),
        React.createElement(
          'div',
          { className: styles.btnContainer },
          React.createElement(
            'a',
            { className: styles.gradientButton, href: 'manageTfa?action=register-key' },
            'Add Security Key'
          )
        )
      );
    }
  }]);

  return SecurityKeyList;
}(React.PureComponent);

function EmptySecurityList() {
  return React.createElement(
    'div',
    { className: styles.bodyContainer },
    'You don\u2019t have any security keys registered.'
  );
}

function SecurityKeys(props) {
  if (props.rows.length === 0) {
    return React.createElement(EmptySecurityList, null);
  } else {
    return React.createElement(TfaTable, _extends({}, props, { ariaLabel: 'List of registered security keys' }));
  }
}

module.exports = SecurityKeyList;
  let __hot__
  
  __registry__.register('tfa/security-key-list', module.exports, __hot__)
  
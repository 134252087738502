'use strict'
const React = require('react')
const styles = require('./header.css')
const connect = require('../connect')
const FadeIn = require('../transitions/fade-in')
const Link = require('@npm/spiferack/link')
const UserIcon = require('../icons/user')
const CogIcon = require('../icons/cog')
const CreditCardIcon = require('../icons/credit-card')
const CoinsIcon = require('../icons/coins')
const PlusIcon = require('../icons/plus')
const BoxesIcon = require('../icons/boxes')
const LogoutIcon = require('../icons/logout')
const DropdownCaret = require('../icons/dropdown-caret')
const { a11yOnly } = require('../../styles/global.css')

class User extends React.PureComponent {
  constructor (props) {
    super(props)

    this.doc = null

    this.keydownHandler = this.keydownHandler.bind(this)
    this.openDropdown = this.openDropdown.bind(this)
  }
  openDropdown () {
    this.props.dispatch({ type: 'NOTIFICATION_CLOSE_ALL' })
    this.props.dispatch({ type: 'USER_DROPDOWN_TOGGLE' })
  }
  closeDropDown () {
    this.props.dispatch({ type: 'USER_DROPDOWN_CLOSE' })
  }
  keydownHandler (ev) {
    const mapping = {
      'Escape': this.closeDropDown.bind(this)
    }
    const fn = mapping[ev.key] || (() => {})
    fn()
  }
  componentDidMount () {
    this.doc = this.props.document || document
    this.doc.addEventListener('keydown', this.keydownHandler)
  }
  componentWillUnmount () {
    this.doc.removeEventListener('keydown', this.keydownHandler)
  }
  render () {
    const { user, userDropdownOpen } = this.props
    return <div className={styles.user}>
      {user && (
        <nav>
          <button
            onClick={this.openDropdown}
            className={styles.dropdownButton}
            aria-expanded={userDropdownOpen}
            aria-label='Profile menu'>
            <span className={a11yOnly}>Menu</span>
            <Gravatar {...user.avatars} />
            <div className='pl1' aria-hidden>
              <DropdownCaret />
            </div>
          </button>
          <Dropdown
            user={user}
            open={userDropdownOpen}
            onBlur={() => this.openDropdown()} />
        </nav>)}
      {!user && <Login />}
    </div>
  }
}

module.exports = connect()(User)

function Login () {
  return <div className={styles.userLogin}>
    <Link href='/signup'>
      <a href='/signup' id='signup' className={styles.userLinkJoin}>Sign Up</a>
    </Link>
    <Link href='/login'>
      <a id='signin' className={styles.userLinkLogin}>Sign In</a>
    </Link>

  </div>
}

function Dropdown ({ user, open, onBlur }) {
  const onBlurDropdown = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      onBlur(event)
    }
  }

  return (
    <FadeIn>
      <div className={styles.userDropdown} hidden={!open}>
        <h2 className={styles.userDropdownHeader}>
          <span className={styles.userName}>
            <UserIcon /> {user.name}
          </span>
        </h2>
        <ul className='list ph0 ma0 mt2' onBlur={(e) => onBlurDropdown(e)}>
          <li className={styles.userDropdownRow} key='profile'>
            <Link>
              <a
                className={styles.userDropdownLink}
                href={`/~${user.name}`}>
                <UserIcon /> Profile
              </a>
            </Link>
          </li>
          <li className={styles.userDropdownRow} key='packages'>
            <Link>
              <a
                className={styles.userDropdownLink}
                href={`/settings/${user.name}/packages`}>
                <BoxesIcon /> Packages
              </a>
            </Link>
          </li>
          <li className={styles.userDropdownRow} key='settings'>
            <Link>
              <a
                className={styles.userDropdownLink}
                href={`/settings/${user.name}/profile`}>
                <CogIcon /> Account
              </a>
            </Link>
          </li>
          <li className={`${styles.userDropdownRow}`} key='billing'>
            <Link>
              <a
                className={styles.userDropdownLink}
                href={`/settings/${user.name}/billing`}>
                <CreditCardIcon /> Billing Info
              </a>
            </Link>
          </li>
          <li className={styles.userDropdownRow} key='tokens'>
            <Link>
              <a
                className={styles.userDropdownLink}
                href={`/settings/${user.name}/tokens`}>
                <CoinsIcon /> Access Tokens
              </a>
            </Link>
          </li>
          <li className={`${styles.userDropdownRow}`} key='create-org'>
            <Link>
              <a
                className={styles.createOrgButton}
                href={`/org/create`}>
                <PlusIcon /> Add Organization
              </a>
            </Link>
          </li>
          <li className={styles.userDropdownRow} key='sign-out'>
            <Link>
              <a
                className={styles.userDropdownLink}
                href='/logout'>
                <LogoutIcon /> Sign Out
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </FadeIn>
  )
}

function Gravatar (avatar) {
  return (
    <img
      alt='avatar'
      className={styles.gravatar}
      src={avatar.small}
      aria-hidden />)
}

'use strict'
const isInvalid = require('npm-user-validate').username
const React = require('react')
const connect = require('../connect')
const GenericInput = require('./generic').unwrapped
const formIdConsumer = require('./form-id-consumer')
const PropTypes = require('prop-types')
const types = require('../../types')

class UsernameInput extends React.Component {
  reflectValidity (ev) {
    const { name, formId } = this.props
    const { target, target: { value } } = ev
    let errorMessage
    const err = isInvalid(value)

    if (value) {
      errorMessage = err ? err.message : ''
    }

    target.setCustomValidity && target.setCustomValidity(errorMessage)

    this.props.dispatch({
      type: 'FORM_VALIDITY_CHECK',
      name,
      formId,
      errorMessage
    })
  }

  render () {
    return <GenericInput
      {...this.props}
      required
      onBlur={ev => this.reflectValidity(ev)} />
  }
}

UsernameInput.propTypes = {
  formId: PropTypes.string,
  formData: types.formDatum.isRequired,
  name: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  label: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func
}

UsernameInput.defaultProps = {
  label: 'Username'
}

module.exports = connect()(formIdConsumer(UsernameInput))

'use strict'
const React = require('react')
const styles = require('../../styles/global.css')
const Link = require('@npm/spiferack/link')

module.exports = function SupportCard () {
  return <p className='mt4 tc fw3 black-90'>
    Need help? <Link>
      <a className={styles.link} href='/support'>Contact</a>
    </Link> support or check out the <Link>
      <a className={styles.link} href='https://docs.npmjs.com/orgs/'>docs</a>
    </Link>.
  </p>
}

'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const types = require('../../types')
const { filter } = require('fuzzaldrin')
const styles = require('./typeahead.css')
const Avatar = require('@npm/design-system/avatar/avatar')
const connect = require('../../components/connect')
const formIdConsumer = require('../inputs/form-id-consumer')

class TypeAheadInput extends React.PureComponent {
  constructor (...args) {
    super(...args)
    this.state = {
      matches: [],
      loading: false,
      selectedIndex: -1
    }
  }

  async onChange (ev) {
    clearTimeout(this.hideListTimeout)
    const { value } = ev.target
    const { objects, getSuggestions } = this.props
    if (!value) return this.setState({ matches: [] })
    if (Array.isArray(objects)) {
      const matches = filter(objects, value, { key: 'name' })
      this.setState({ matches })
    } else if (objects) {
      // it's an async generator
      if (!this.listPromise) {
        this.listPromise = Promise.all([...objects])
      }
      this.setState({ loading: true })
      const asyncObjects = await this.listPromise
      const matches = filter(asyncObjects, value, { key: 'name' })
      this.setState({ matches, loading: false })
    } else {
      // we have a getSuggestions funcion
      this.setState({ matches: [], loading: true })
      const matches = await getSuggestions(value)
      // user could have hit enter before the suggestions load
      if (!this.state.loading) return
      this.setState({ matches, loading: false })
    }
  }

  onBlur (ev) {
    // defer this, because clicking on a value in the list is considered a blur
    // event, and this event has higher precedence than the onSelect handler
    this.hideListTimeout = setTimeout(() => this.setState({ matches: [] }), 200)
  }

  onKeyUp (ev) {
    const { selectedIndex, matches } = this.state
    const { length } = matches

    const mappings = {
      Enter: () => {
        const match = matches[selectedIndex]
        if (match) {
          this.onSelect(match.name)
          ev.preventDefault()
        }
        this.closeList()
      },
      Escape: () => { this.closeList() },
      ArrowDown: () => {
        matches.length && this.setState({ selectedIndex: (selectedIndex + 1) % length })
      },
      ArrowUp: () => {
        matches.length && this.setState({ selectedIndex: (selectedIndex - 1) % length })
      }
    }

    const fn = mappings[ev.key] || (() => {})

    fn()
  }

  closeList () {
    this.setState({ matches: [], selectedIndex: -1, loading: false })
  }

  onSelect (name) {
    const { onSelect } = this.props
    this.setState({ matches: [] })
    this.updateFormData(name)
    onSelect(name)
  }

  updateFormData (value) {
    const {
      dispatch, formId
    } = this.props
    if (formId) {
      dispatch({
        type: 'FORM_CHANGE',
        formId: formId,
        name: this.inputName,
        value
      })
    }
  }

  render () {
    const { inputElem, formId, name, formData: propsFormData, renderRow, title } = this.props
    const { matches, loading, selectedIndex } = this.state
    let formData = {
      [formId]: {
        [name]: matches[selectedIndex]
          // override the formData when using arrow keys, so the input changes
          // without triggering a suggestions update
          ? { value: matches[selectedIndex].name }
          : propsFormData
      }
    }

    // bit of a hack to do this here, but cleaner than creating
    // componentDidMount and componentDidUpdate handlers
    this.inputName = inputElem.props.name

    const hasMatches = matches.length > 0

    return <div className={`${styles.typeahead} ${this.props.className}`}>
      {hasMatches && <div
        className={styles.backdrop}
        onClick={() => this.closeList()} />}
      {React.cloneElement(inputElem, {
        formData,
        onBlur: ev => this.onBlur(ev),
        onKeyUp: ev => this.onKeyUp(ev),
        onChange: ev => this.onChange(ev),
        autoComplete: 'off',
        'aria-label': this.props['aria-label'] ? this.props['aria-label'] : null
      })}
      {loading && <MatchesList matches={[{ name: '…' }]} />}
      {hasMatches &&
        <MatchesList
          matches={matches}
          onSelect={name => this.onSelect(name)}
          selectedIndex={selectedIndex}
          renderRow={renderRow}
          title={title || ''} />}
    </div>
  }
}

function MatchesList (props) {
  const {
    matches,
    onSelect,
    selectedIndex,
    renderRow = TypeAheadInput.defaultProps.renderRow,
    title
  } = props
  return <ul aria-label={title} className={styles.typeaheadList}>
    {matches.map((object, index) => {
      const { name } = object
      const highlightClass = selectedIndex === index ? styles.highlight : ''
      return <li
        key={name}
        className={`${styles.typeaheadListItem} ${highlightClass}`}
        onClick={() => onSelect(name)}>
        {renderRow(object, index)}
      </li>
    })}
  </ul>
}

TypeAheadInput.propTypes = {
  inputElem: PropTypes.element.isRequired,
  objects: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      resource: PropTypes.shape({
        fullname: PropTypes.string
      }),
      avatars: PropTypes.object
    })),
    PropTypes.shape({
      next: PropTypes.func.isRequired
    })
  ]),
  getSuggestions: PropTypes.func,
  onSelect: PropTypes.func,
  renderRow: PropTypes.func,
  formData: types.formDatum,
  className: PropTypes.string
}

TypeAheadInput.defaultProps = {
  onSelect () {},
  renderRow (object, index) {
    const { name, avatars, resource = {} } = object
    return [
      avatars && <Avatar key='avatar' src={avatars.small || ''} size='tiny' />,
      <span key='name'>{name}</span>,
      resource.fullname ? <span key='fullname' className={styles.fullName}>{resource.fullname}</span> : null
    ]
  },
  className: ''
}

module.exports = connect()(formIdConsumer(TypeAheadInput))

'use strict'

const React = require('react')
const styles = require('./tabs.css')
const PropTypes = require('prop-types')
const Link = require('@npm/spiferack/link')
const connect = require('../connect')

function Tabs ({ links, colors, active, dispatch }) {
  const tabIds = links.map(({ key }) => `package-tab-${key}`)
  const refs = React.useRef([])
  return (
    <ul className={styles.tabs} role='tablist' aria-owns={tabIds.join(' ')}>
      {
        links.map(({ href, label, key }, index) => {
          const selected = active === key
          const color = colors[index % colors.length]
          let classes = `${styles.tab} ${styles[color]}`
          if (selected) classes += ` ${styles.tabActive}`
          const url = href || `?activeTab=${key}`
          return (
            <li className={classes} key={key} role='presentation'>
              {
                href
                  ? (
                    <Link>
                      <a className={styles.tabLink} href={href} aria-selected={selected} role='tab' aria-controls={`tabpanel-${key}`} id={`package-tab-${key}`} onKeyDown={event => keyDown(event, key)} ref={(ref) => { refs.current[index] = ref }}>
                        {label}
                      </a>
                    </Link>
                  ) : (
                    <a className={styles.tabLink} href={url} aria-selected={selected} onClick={tabClick(key)} onKeyDown={event => keyDown(event, key)} ref={(ref) => { refs.current[index] = ref }} role='tab' aria-controls={`tabpanel-${key}`} id={`package-tab-${key}`}>
                      {label}
                    </a>
                  )
              }
            </li>
          )
        })
      }
    </ul>
  )

  function tabClick (key) {
    return ev => {
      if (ev.ctrlKey) return
      ev.preventDefault()
      dispatch({ type: 'PACKAGE_TAB', activeTab: key })
      dispatch({ type: 'TAB_CLICKED', tabClicked: true })
    }
  }

  function keyDown (event, tabKey) {
    switch (event.key) {
      case 'ArrowRight':
      case 'ArrowDown':
        shiftTabFocus(event, tabKey, 1)
        break
      case 'ArrowLeft':
      case 'ArrowUp':
        shiftTabFocus(event, tabKey, -1)
        break
      default :
    }
  }

  function shiftTabFocus (event, tabKey, shiftIndex) {
    event.preventDefault()
    const nextIndex = findNextLinkIndex(tabKey, shiftIndex)
    refs.current[nextIndex].focus()
  }

  function findNextLinkIndex (key, shiftIndex) {
    const tabIndex = links.findIndex(link => link.key === key)
    let nextIndex = tabIndex + shiftIndex
    if (nextIndex < 0) {
      nextIndex = links.length - 1
    } else if (nextIndex >= links.length) {
      nextIndex = 0
    }
    return nextIndex
  }
}

Tabs.defaultProps = {
  colors: ['red', 'yellow', 'green', 'violet', 'purple', 'teal']
}

Tabs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.node.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired,
  colors: PropTypes.arrayOf(PropTypes.oneOf(Tabs.defaultProps.colors))
}

module.exports = connect()(Tabs)
module.exports.component = Tabs

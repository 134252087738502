'use strict'

const React = require('react')
const Expansions = require('./expansions')
const ProductNav = require('./product-nav')
const Logo = require('./logo')
const User = require('./user')
const Search = require('./search')

const styles = require('./header.css')
const { a11yOnly } = require('../../styles/global.css')

module.exports = function Header (props) {
  const renderSkipToLinkHeader = () => {
    var orSkipToSignIn = null
    if (!props.user) {
      orSkipToSignIn = <React.Fragment>
        <span> or </span>
        <a href='#signin'>
          skip to sign in
        </a>
      </React.Fragment>
    }

    return <div id='header' className={styles.skipToLinkHeader}>
      <a href='#search'>
        skip to package search
      </a>
      {
        orSkipToSignIn
      }
    </div>
  }

  return <header className={styles.header}>
    {renderSkipToLinkHeader()}

    <div className={styles.headerGakbar} />

    <div className={styles.headerMain}>
      <div className={styles.headerMainContainer}>
        <span className={`${styles.firstPublish}`}>❤</span>
        <Expansions expansions={props.npmExpansions} />
        <ProductNav />
      </div>
    </div>

    <div className={styles.headerLinks}>
      <div className={styles.headerLinksContainer}>
        <h1 className={a11yOnly}>npm</h1>
        <div className={styles.logo}>
          <Logo />
        </div>
        <Search id='search' formData={props.formData} />
        <User user={props.user} userDropdownOpen={props.userDropdownOpen} />
      </div>
    </div>

  </header>
}

const React = require('react')
const formIdConsumer = require('./form-id-consumer')
const connect = require('../connect')
const GenericInput = require('./generic').unwrapped
const PropTypes = require('prop-types')
const types = require('../../types')

class PasswordInput extends React.Component {
  reflectValidity (e) {
    const { name, formId, minLength } = this.props
    const { target } = e
    const valid = target.checkValidity ? target.checkValidity() : true
    const errorMessage = valid
      ? ''
      : Number(minLength)
        ? `Please enter a valid password that is at least ${minLength} characters`
        : 'Please enter a password'

    this.props.dispatch({
      type: 'FORM_VALIDITY_CHECK',
      name,
      formId,
      errorMessage
    })
  }

  onBlurHandler (e) {
    const { onBlur } = this.props
    this.reflectValidity(e)
    onBlur(e)
  }

  render () {
    return <GenericInput
      {...this.props}
      type='password'
      onBlur={ev => this.onBlurHandler(ev)}
      onChange={ev => this.reflectValidity(ev)} />
  }
}

PasswordInput.propTypes = {
  formId: PropTypes.string,
  formData: types.formDatum,
  name: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
}

PasswordInput.defaultProps = {
  label: 'Password',
  minLength: '10',
  required: true,
  onBlur: () => {},
  onChange: () => {}
}

module.exports = connect()(formIdConsumer(PasswordInput))

const React = require('react')

module.exports = plus

function plus () {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 11'>
      <path fillRule='evenodd' d='M4.9 6.376H.78V4.444H4.9V.097h2v4.347h4.117v1.932H6.9v4.347h-2' />
    </svg>
  )
}

'use strict'

const React = require('react')
const Link = require('@npm/spiferack/link')
const styles = require('./header.css')

module.exports = class ProductNav extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      productsMenuIsOpen: false
    }

    this.onKeyDownProducts = this.onKeyDownProducts.bind(this)
    this.onBlurProducts = this.onBlurProducts.bind(this)
    this.onKeyDownProductsMenu = this.onKeyDownProductsMenu.bind(this)
  }

  openProductsMenu () {
    this.setState({ productsMenuIsOpen: true })
  }

  closeProductsMenu () {
    this.setState({ productsMenuIsOpen: false })
  }

  onKeyDownProducts (event) {
    if (event.key === 'Enter') {
      if (!this.state.productsMenuIsOpen) {
        event.preventDefault()
      }
      this.openProductsMenu()
    }
  }

  onKeyDownProductsMenu (event) {
    if (event.key === 'Escape') {
      this.closeProductsMenu()
    }
  }

  onBlurProducts (event) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.closeProductsMenu()
    }
  }

  render () {
    const visibleMenuClass = this.state.productsMenuIsOpen ? styles.productNavMenuVisible : ''
    return <nav className={styles.productNav} aria-label='Product Navigation'>
      <ul className='list pl0'>
        <li className='dib' onBlur={this.onBlurProducts} onKeyDown={this.onKeyDownProductsMenu}>
          <Link href='/products'>
            <a className={`${styles.productNavLink} pr2 pl2`} id='nav-products-link' onKeyDown={this.onKeyDownProducts}>
              <span className='dim'>Products</span>
            </a>
          </Link>
          <ul className={`${styles.productNavMenu} ${visibleMenuClass} mt2 pa0 bg-white br2 shadow-1 tl`}>
            <li>
              <Link href='/products/pro'>
                <a id='nav-pro-link' className={`${styles.productNavMenuItem} db ph3 pv2 nowrap dim`} aria-label='npm professional'>Pro</a>
              </Link>
            </li>

            <li>
              <Link href='/products/teams'>
                <a id='nav-teams-link' className={`${styles.productNavMenuItem} db ph3 pv2 nowrap dim`} aria-label='npm teams'>Teams</a>
              </Link>
            </li>
          </ul>
        </li>
        <li className='dib'>
          <Link href='/products'>
            <a className={`${styles.productNavLink} dim pr2 pl2`} id='nav-pricing-link'>Pricing</a>
          </Link>
        </li>
        <li className='dib'>
          <Link href='https://docs.npmjs.com'>
            <a className={`${styles.productNavLink} dim pr2 pl2`} id='nav-docs-link'>Documentation</a>
          </Link>
        </li>
      </ul>
    </nav>
  }
}


  const __registry__ = require('../../../spiferack/client/index.js')
  var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var React = require('react');
var DropdownStyle = require('./list.css');
var DropdownCaret = require('../../components/icons/dropdown-caret');
var forms = require('../../styles/forms.css');
var FadeIn = require('../../components/transitions/fade-in');
var Link = require('@npm/spiferack/link');

var GenerateTokenButton = function (_React$PureComponent) {
  _inherits(GenerateTokenButton, _React$PureComponent);

  function GenerateTokenButton(props) {
    _classCallCheck(this, GenerateTokenButton);

    var _this = _possibleConstructorReturn(this, (GenerateTokenButton.__proto__ || Object.getPrototypeOf(GenerateTokenButton)).call(this, props));

    _this.state = {
      userDropdownOpen: false
    };
    return _this;
  }

  _createClass(GenerateTokenButton, [{
    key: 'openDropdown',
    value: function openDropdown() {
      this.setState(function (prevState) {
        return {
          userDropdownOpen: !prevState.userDropdownOpen
        };
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          createGATURL = _props.createGATURL,
          createURL = _props.createURL;


      return React.createElement(
        'a',
        {
          onClick: function onClick() {
            return _this2.openDropdown();
          },
          className: forms.buttonGradientGreen + ' ' + DropdownStyle.posRelative + ' mt0' },
        'Generate New Token ',
        React.createElement(DropdownCaret, null),
        React.createElement(Dropdown, { createGATURL: createGATURL,
          createURL: createURL, open: this.state.userDropdownOpen })
      );
    }
  }]);

  return GenerateTokenButton;
}(React.PureComponent);

function Dropdown(_ref) {
  var createGATURL = _ref.createGATURL,
      createURL = _ref.createURL,
      open = _ref.open;

  return React.createElement(
    FadeIn,
    null,
    React.createElement(
      'div',
      { className: DropdownStyle.createTokenDropdown, hidden: !open },
      React.createElement(
        'ul',
        { className: 'list ph0 ma0' },
        React.createElement(
          'li',
          { className: DropdownStyle.createTokenDropdownRow, key: 'profile' },
          React.createElement(
            Link,
            null,
            React.createElement(
              'a',
              {
                className: DropdownStyle.createTokenDropdownLink,
                href: createGATURL },
              'Granular Access Token ',
              React.createElement(
                'label',
                { className: DropdownStyle.betaLabel },
                'Beta'
              )
            )
          )
        ),
        React.createElement(
          'li',
          { className: DropdownStyle.createTokenDropdownRow, key: 'packages' },
          React.createElement(
            Link,
            null,
            React.createElement(
              'a',
              {
                className: DropdownStyle.createTokenDropdownLink,
                href: createURL },
              'Legacy Token'
            )
          )
        )
      )
    )
  );
}

module.exports = GenerateTokenButton;
  let __hot__
  
  __registry__.register('tokens/generate-token', module.exports, __hot__)
  
'use strict'

const React = require('react')
const PropTypes = require('prop-types')

const ButtonOutline = require('@primer/components/lib/Button/ButtonOutline').default
const ButtonPrimary = require('@primer/components/lib/Button/ButtonPrimary').default
const PrimerDialog = require('@primer/components/lib/Dialog').default
const withThemeProvider = require('../theme-provider')
const Flex = require('@primer/components/lib/Flex').default
const Box = require('@primer/components/lib/Box').default
const styles = require('./dialog.css')

function Dialog (props) {
  const {
    isOpen,
    onClick,
    headerText,
    cancelText,
    doActionText,
    ariaLabelledBy,
    children,
    onDismiss
  } = props

  return <div className={isOpen ? styles.overlay : undefined} >
    <PrimerDialog
      isOpen={isOpen}
      sx={{ width: '600px' }}
      className={styles.dialog}
      onDismiss={onDismiss}
      aria-labelledby={ariaLabelledBy}>
      { headerText && <PrimerDialog.Header id='header-id'>{ headerText }</PrimerDialog.Header> }
      <Box p={3} >
        { children }
        <Flex mt={3} justifyContent='flex-end'>
          { cancelText &&
          <ButtonOutline
            mr={1}
            className={styles.buttonOutline}
            onClick={onDismiss} >
            { cancelText }
          </ButtonOutline>
          }
          { onClick &&
          <ButtonPrimary
            className={styles.buttonPrimary}
            onClick={onClick}>
            { doActionText || 'Okay' }
          </ButtonPrimary> }
        </Flex>
      </Box>
    </PrimerDialog>
  </div>
}

Dialog.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  headerText: PropTypes.string,
  cancelText: PropTypes.string,
  doActionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  ariaLabelledBy: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired
}

module.exports = withThemeProvider(Dialog)

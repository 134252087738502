
  const __registry__ = require('../../../spiferack/client/index.js')
  'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _require = require('react-helmet'),
    Helmet = _require.Helmet;

var PropTypes = require('prop-types');
var React = require('react');

var GenericInput = require('../../components/inputs/generic');
var GenericForm = require('../../components/forms/generic');
var styles = require('./auth.css');
var LoginLockIcon = require('../../components/icons/login-lock');
var LiminalLoginLayout = require('../../components/layouts/liminal-login');
var UserAvatarHeaderComponent = require('../../components/auth/user-avatar-header-component');
var HiddenInput = require('../../components/inputs/hidden');

var _require2 = require('./liminal-login-footer'),
    TotpLoginFooter = _require2.TotpLoginFooter,
    TotpEscalateFooter = _require2.TotpEscalateFooter;

var CooldownOptin = require('../../components/auth/cooldown-optin');

var OTPLogin = function (_React$PureComponent) {
  _inherits(OTPLogin, _React$PureComponent);

  function OTPLogin(props) {
    _classCallCheck(this, OTPLogin);

    var _this = _possibleConstructorReturn(this, (OTPLogin.__proto__ || Object.getPrototypeOf(OTPLogin)).call(this, props));

    _this.state = {
      didOptForCooldown: false
    };

    _this.processCooldownOptIn = _this.processCooldownOptIn.bind(_this);
    return _this;
  }

  _createClass(OTPLogin, [{
    key: 'processCooldownOptIn',
    value: function processCooldownOptIn(cooldownChecked) {
      this.setState({
        didOptForCooldown: cooldownChecked
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          setEscalateType = _props.setEscalateType,
          isTfaEscalate = _props.isTfaEscalate,
          action = _props.action,
          _props$formData = _props.formData,
          formData = _props$formData === undefined ? {} : _props$formData,
          hasWebAuthnDevices = _props.hasWebAuthnDevices,
          webAuthnUrl = _props.webAuthnUrl,
          recoveryCodeUrl = _props.recoveryCodeUrl,
          isNewCliLoginFlowEscalation = _props.isNewCliLoginFlowEscalation,
          isNewPublishAuthEscalation = _props.isNewPublishAuthEscalation,
          originalUrl = _props.originalUrl;


      var onSubmit = null;
      // noop to get default form submit
      if (action.match(/^\/login/)) onSubmit = function onSubmit() {};

      var user = isNewPublishAuthEscalation ? this.props.tokenUser : this.props.user;

      var Header = (isNewCliLoginFlowEscalation || isNewPublishAuthEscalation) && user ? React.createElement(UserAvatarHeaderComponent, _extends({ tokenUser: user }, this.props)) : 'Two-Factor Authentication';

      var Footer = void 0;
      if (isTfaEscalate) {
        Footer = React.createElement(TotpEscalateFooter, { hasWebAuthnDevices: hasWebAuthnDevices, setEscalateType: setEscalateType });
      } else if (isNewPublishAuthEscalation || isNewCliLoginFlowEscalation) {
        Footer = React.createElement(TotpLoginFooter, { hasWebAuthnDevices: hasWebAuthnDevices, webAuthnUrl: webAuthnUrl });
      } else {
        Footer = React.createElement(TotpLoginFooter, { hasWebAuthnDevices: hasWebAuthnDevices, webAuthnUrl: webAuthnUrl, recoveryCodeUrl: recoveryCodeUrl });
      }
      return React.createElement(
        LiminalLoginLayout,
        { Footer: Footer, Header: Header },
        React.createElement(
          'div',
          { className: styles.login },
          React.createElement(
            Helmet,
            null,
            React.createElement(
              'title',
              null,
              'npm | Enter a One-time Password'
            )
          ),
          React.createElement(
            GenericForm,
            {
              action: action,
              formId: 'login',
              formData: formData,
              onSubmit: onSubmit,
              buttonText: 'Login',
              className: 'center' },
            React.createElement(
              'div',
              { className: 'tc' },
              React.createElement(LoginLockIcon, null)
            ),
            React.createElement(
              'h2',
              { className: 'tc' },
              'Enter One-time Password'
            ),
            React.createElement(
              'p',
              null,
              'Enter a 6-digit code from your \u2009',
              React.createElement(
                'a',
                { href: 'https://docs.npmjs.com/configuring-two-factor-authentication#prerequisites' },
                'authenticator device'
              )
            ),
            React.createElement(CooldownOptin, _extends({}, this.props, { onCooldownOptinChange: function onCooldownOptinChange() {}, didOptForCooldown: this.state.didOptForCooldown, formData: formData })),
            React.createElement(GenericInput, { inputMode: 'numeric', autoComplete: 'off', autoFocus: true, name: 'otp', label: 'One-time Password', formData: formData }),
            React.createElement(HiddenInput, { formId: 'escalate-type', name: 'formName', value: 'totp', formData: formData }),
            React.createElement(HiddenInput, { formId: 'originalUrl', name: 'originalUrl', value: originalUrl, formData: formData })
          )
        )
      );
    }
  }]);

  return OTPLogin;
}(React.PureComponent);

OTPLogin.layout = 'none';

OTPLogin.propTypes = {
  action: PropTypes.string.isRequired
};

module.exports = OTPLogin;
  let __hot__
  
  __registry__.register('auth/otp', module.exports, __hot__)
  
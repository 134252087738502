'use strict'

const React = require('react')
const { MarkGithubIcon } = require('@primer/octicons-react')

class GitHubIcon extends React.PureComponent {
  render () {
    const size = this.props.size || 16
    const className = this.props.className || ''
    const verticalAlign = this.props.verticalAlign || 'top'
    // per style guide, the Invertocat must always be black
    return <MarkGithubIcon fill='black' size={size} className={className} verticalAlign={verticalAlign} />
  }
}

module.exports = GitHubIcon

'use strict'

const React = require('react')
const Expansions = require('./expansions')
const Logo = require('./logo')
const Search = require('./search')

const styles = require('./header.css')
const { a11yOnly } = require('../../styles/global.css')

module.exports = function Header (props) {
  return <header className={styles.header}>

    <div className={styles.headerMain}>
      <div className={styles.headerMainContainer}>
        <Expansions expansions={props.npmExpansions} />
      </div>
    </div>

    <div className={styles.headerLinks}>
      <div className={styles.headerLinksContainer}>
        <h1 className={a11yOnly}>npm</h1>
        <div className={styles.logo}>
          <Logo />
        </div>
        <Search formData={props.formData} />
      </div>
    </div>

  </header>
}


  const __registry__ = require('../../../../spiferack/client/index.js')
  'use strict';

var _validators;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var isCidr4 = require('is-cidr').v4;
var moment = require('moment');

var _require = require('./constants'),
    TOKEN_NAME = _require.TOKEN_NAME,
    EXPIRATION_DAYS = _require.EXPIRATION_DAYS,
    EXPIRATION_ERROR = _require.EXPIRATION_ERROR,
    ALLOWED_IP_RANGES = _require.ALLOWED_IP_RANGES,
    IP_RANGES_ERROR = _require.IP_RANGES_ERROR,
    PERMISSION_NO_ACCESS = _require.PERMISSION_NO_ACCESS,
    PERMISSION_READ_ONLY = _require.PERMISSION_READ_ONLY,
    PERMISSION_READ_WRITE = _require.PERMISSION_READ_WRITE,
    SELECTED_PACKAGES_AND_SCOPES_ALL = _require.SELECTED_PACKAGES_AND_SCOPES_ALL,
    TOKEN_DESCRIPTION = _require.TOKEN_DESCRIPTION,
    TOKEN_NAME_TOO_LONG_ERROR = _require.TOKEN_NAME_TOO_LONG_ERROR,
    TOKEN_DESCRIPTION_TOO_LONG_ERROR = _require.TOKEN_DESCRIPTION_TOO_LONG_ERROR,
    TOKEN_NAME_EMPTY_ERROR = _require.TOKEN_NAME_EMPTY_ERROR,
    MAX_TOKEN_NAME_LEN = _require.MAX_TOKEN_NAME_LEN,
    MAX_TOKEN_DESCRIPTION_LEN = _require.MAX_TOKEN_DESCRIPTION_LEN,
    TOKEN_NAME_CONTENT_ERROR = _require.TOKEN_NAME_CONTENT_ERROR;

var validators = (_validators = {}, _defineProperty(_validators, TOKEN_NAME, function (tokenName) {
  tokenName = tokenName.trim();
  if (tokenName.length === 0) {
    return { error: TOKEN_NAME_EMPTY_ERROR };
  }
  if (tokenName.length > MAX_TOKEN_NAME_LEN) {
    return { error: TOKEN_NAME_TOO_LONG_ERROR };
  }
  var pattern = new RegExp('^[\\w-]+[\\w- ]*$');
  if (!pattern.test(tokenName)) {
    return { error: TOKEN_NAME_CONTENT_ERROR };
  }
  return {};
}), _defineProperty(_validators, TOKEN_DESCRIPTION, function (tokenDescription) {
  tokenDescription = tokenDescription.trim();
  if (tokenDescription.length > MAX_TOKEN_DESCRIPTION_LEN) {
    return { error: TOKEN_DESCRIPTION_TOO_LONG_ERROR };
  }
  return {};
}), _defineProperty(_validators, EXPIRATION_DAYS, function (expirationDays) {
  var valid = Number(expirationDays) >= 1 && Number(expirationDays) <= 365;
  if (!valid) {
    return { error: EXPIRATION_ERROR };
  }
  return {};
}), _defineProperty(_validators, ALLOWED_IP_RANGES, function (allowedIPRanges) {
  var valid = true;
  for (var i = 0; i < allowedIPRanges.length; i++) {
    if (allowedIPRanges[i] === '' || allowedIPRanges[i] === undefined) {
      continue;
    }
    if (!isCidr4(allowedIPRanges[i])) {
      valid = false;
      break;
    }
  }
  if (!valid) {
    return { error: IP_RANGES_ERROR };
  }
  return {};
}), _validators);

function permissionPhrase(permission) {
  switch (permission) {
    case PERMISSION_NO_ACCESS:
      return 'no';
    case PERMISSION_READ_ONLY:
      return 'read-only';
    case PERMISSION_READ_WRITE:
      return 'read and write';
    default:
      return 'no';
  }
}

// Works for the words - scope, package, organization.
function pluralize(word, count) {
  return count === 1 ? word : word + 's';
}

// Return the date after the `days` days in the format 'Monday, January 1, 2020'.
// startDate is the date to start from, in the format 'YYYY-MM-DD'.
function dateAfterDays(startDate, days) {
  var newDate = moment(startDate, 'YYYY-MM-DD').add(days, 'days');
  return newDate.format('dddd, MMMM D, YYYY');
}

// If permission is No access, count should be empty.
// If all packages/scopes/orgs are selected then all should be the corresponding count phrase.
// Else, the actual count of the items.
function packagesCountPhrase(permission, selectedPackagesAndScopes, selectedCount) {
  if (permission === PERMISSION_NO_ACCESS) {
    return '';
  }
  if (selectedPackagesAndScopes === SELECTED_PACKAGES_AND_SCOPES_ALL) {
    return 'all';
  }
  return selectedCount;
}

function countPhrase(permission, selectedCount) {
  if (permission === PERMISSION_NO_ACCESS) {
    return '';
  }
  return selectedCount;
}

function pkgsAndScopesCountPhrase(pkgsPhrase, scopesPhrase) {
  // Only some scopes selected
  if (pkgsPhrase.startsWith('0') && !scopesPhrase.startsWith('0')) {
    return scopesPhrase;
  }
  // Only some packages selected
  if (!pkgsPhrase.startsWith('0') && scopesPhrase.startsWith('0')) {
    return pkgsPhrase;
  }
  // Either some scopes + packages selected
  // or none selected (which shouldn't happen)
  return pkgsPhrase + ' and ' + scopesPhrase;
}

module.exports = {
  validators: validators,
  permissionPhrase: permissionPhrase,
  pluralize: pluralize,
  dateAfterDays: dateAfterDays,
  packagesCountPhrase: packagesCountPhrase,
  countPhrase: countPhrase,
  pkgsAndScopesCountPhrase: pkgsAndScopesCountPhrase
};
  let __hot__
  
  __registry__.register('tokens/gat/utils', module.exports, __hot__)
  
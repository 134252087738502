
  const __registry__ = require('../../../spiferack/client/index.js')
  'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var React = require('react');
var Router = require('@npm/spiferack/router');

var styles = require('./list.css');

var AdditionalOptions = function (_React$PureComponent) {
  _inherits(AdditionalOptions, _React$PureComponent);

  function AdditionalOptions(props) {
    _classCallCheck(this, AdditionalOptions);

    var _this = _possibleConstructorReturn(this, (AdditionalOptions.__proto__ || Object.getPrototypeOf(AdditionalOptions)).call(this, props));

    _this.state = {
      selected: props.tfaMode === 'auth-and-writes',
      tfaMode: props.tfaMode
    };

    _this.onChangeTfaMode = _this.onChangeTfaMode.bind(_this);
    _this.onSubmitTfaMode = _this.onSubmitTfaMode.bind(_this);
    return _this;
  }

  _createClass(AdditionalOptions, [{
    key: 'onSubmitTfaMode',
    value: function onSubmitTfaMode() {
      var _props = this.props,
          modifyTFAModeUrl = _props.modifyTFAModeUrl,
          csrftoken = _props.csrftoken;

      var mode = this.state.tfaMode;

      return Router.get().submit({
        action: modifyTFAModeUrl,
        method: 'POST',
        data: {
          csrftoken: csrftoken,
          mode: mode
        }
      });
    }
  }, {
    key: 'onChangeTfaMode',
    value: function onChangeTfaMode() {
      var isChecked = !this.state.selected; // this.state.selected is the state before onChange action
      this.setState({
        selected: isChecked,
        tfaMode: isChecked ? 'auth-and-writes' : 'auth-only'
      });
    }
  }, {
    key: 'render',
    value: function render() {
      return React.createElement(
        'div',
        null,
        React.createElement(
          'div',
          { className: styles.additionalOptions },
          React.createElement('input', {
            type: 'checkbox',
            'aria-label': 'Require two-factor authentication for write actions',
            checked: this.state.selected,
            onChange: this.onChangeTfaMode,
            style: { marginRight: '10px' }
          }),
          React.createElement(
            'span',
            null,
            'Require two-factor authentication for write actions'
          )
        ),
        React.createElement(
          'div',
          { className: styles.btnContainer },
          React.createElement(
            'button',
            {
              className: styles.gradientButton,
              disabled: this.state.tfaMode === this.props.tfaMode,
              onClick: this.onSubmitTfaMode
            },
            'Update Preferences'
          )
        )
      );
    }
  }]);

  return AdditionalOptions;
}(React.PureComponent);

module.exports = AdditionalOptions;
  let __hot__
  
  __registry__.register('tfa/additional-options', module.exports, __hot__)
  
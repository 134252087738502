'use strict'

const React = require('react')
const connect = require('../connect')
const header = require('./header.css')

class Expansions extends React.PureComponent {
  constructor (props) {
    super(props)
    this.resample = this.resample.bind(this)
  }

  resample () {
    if (this.props.expansions.length === 1) {
      window.location = 'https://github.com/npm/npm-expansions'
      return
    }
    this.props.dispatch('NPM_EXPANSION')
  }

  render () {
    const { expansions } = this.props
    if (!expansions) return null
    return <span className={header.expansions} onClick={this.resample}>
      {expansions[expansions.length - 1]}
    </span>
  }
}

module.exports = connect()(Expansions)
module.exports.component = Expansions // Exposing un-connected component for testing

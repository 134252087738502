
  const __registry__ = require('../../../../spiferack/client/index.js')
  'use strict';

var React = require('react');
var gatStyles = require('../gat.css');

function Divider(props) {
  var classNames = props.classNames;

  return React.createElement('div', { className: gatStyles.divider + ' ' + classNames });
}

module.exports = Divider;
  let __hot__
  
  __registry__.register('tokens/gat/divider', module.exports, __hot__)
  